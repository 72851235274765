<template>
    <div class="workshop">
        <Navigation/>
        <v-main>
            <v-container v-if="$acl.not.check('isProPremium')">
                <v-row>
                    <v-col cols="12">
                        <NotPremiumCard/>
                    </v-col>
                </v-row>
            </v-container>

            <v-container v-else>
                <v-row>
                    <v-col cols="12" v-if="loading">
                        <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" v-else>
                        <WorkshopDetails ref="WorkshopDetails" v-model="workshop" v-if="workshop.id" :color="isAja ? '#294D5B' : 'secondary'"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import WorkshopDetails from '@/components/workshops/Details'
export default {
    components: {
        WorkshopDetails,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            workshop: {},
            loading: false,
        }
    },
    mounted () {
        this.getWorkshop()
    },
    computed: {
        isAja() {
            return this.$store.getters.getProfile.is_aja
        },
    },
    methods: {
        getWorkshop() {
            this.loading = true
            this.$store.dispatch('workshopRequest', {id: this.id, with: ['user', 'meetings', 'partner.logo', 'nextSubstitutions.user']})
                .then(result => {
                    this.workshop = result.data
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
                .finally(() => this.loading = false)
        },
    }
}
</script>