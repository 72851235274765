<template>
    <!-- AJA, logged but not premium : Display "Finaliser l'inscription" dialog -->
    <v-card v-if="$acl.check('isAuth') && !$acl.check('isUserPremium')" color="#1E3945">
        <v-card-title>
            Terminer l'inscription
        </v-card-title>
        <v-card-text>
            <p>Afin de finaliser la création de l'espace personnel
                <v-btn color="green" text :to="{ name: 'Payment', query: { subscription: 1 } }" depressed>
                    cliquer ICI
                </v-btn>
            </p>
        </v-card-text>
    </v-card>

    <!-- Else (AJA, logged, premium) : display QR and profile dialog -->
    <v-card v-else color="#1E3945">
        <v-card-title>
            Espace Personnel incomplet
        </v-card-title>
        <v-card-text>
            <p>Merci de renseigner les informations suivantes</p>

            <li v-for="item in incompleteProfileMissingData" :key="item.text">
                <span>{{ item.text }}</span> <v-btn @click="$router.push(item.route)" text color="#4E983C">{{ item.btnText }}</v-btn>
            </li>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        incompleteProfileMissingData: {
            type: Array,
            required: true
        }
    },
}
</script>
