<template>
    <div class="workshop">
        <v-card elevation="4" :color="color" dark>
            <v-card-text>
                <v-row align="center" class="headline" no-gutters>
                    <v-col class="font-weight-bold white--text">{{ value.name }}</v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row v-if="isThePro">
            <v-col cols="12">
                <v-card elevation="4" class="mt-2" :color="isThemeAja ? '#1E3945' : ''" >
                    <v-card-text class="subtitle-1">
                        <v-row justify="space-around">
                            <v-col cols="auto">
                                Atelier <b>{{ value.type_name }}</b>
                            </v-col>
                            <v-col cols="auto">
                                Atelier <b v-if="value.active">visible</b><b v-else>désactivé</b>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="value.description">
            <v-col cols="12">
                <v-card :color="isThemeAja ? '#1E3945' : ''" elevation="4">
                    <v-card-text class="subtitle-1">
                        {{ value.description }}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="space-around" align="stretch">
            <v-col cols="12" :md="6">
                <WorkshopCard style="height: 220px; width: auto" ref="WorkshopCard" v-model="value" :type="value.type" :options="{hideTitle: true}" :color="color" class="fill-height"/>
            </v-col>

            <v-col cols="12" :md="6" v-if="logo_path">
                <v-img style="background-color: #1E1E1E;" :src="logo_path" width="auto" contain height="220px"  class="mt-3"></v-img>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12" class="mb-5">
                <v-card :color="isThemeAja ? '#1E3945' : ''" elevation="4">
                    <v-card-title class="justify-center">{{ meetingsTitle }}</v-card-title>
                    <v-card-text>
                        <v-row class="mx-n4">
                            <v-col cols="12" class="mx-0 body-1 font-weight-bold white--text">
                                Le n° de la séquence n’a pas d’importance, vous pouvez commencer n’importe quand.
                            </v-col>
                            <v-col cols="12">
                                <v-data-table 
                                    :style="{backgroundColor: isThemeAja ? '#1E3945' : color}" 
                                    :items="loadedMeetings"
                                    :items-per-page="-1"
                                    item-key="id"
                                    :headers="meetingsHeader"
                                    :options.sync="options"
                                    :show-expand="isThePro"
                                    :item-class="(item, isExpanded) => {return {'grey--text': $moment().diff(item.start) > 0}}"
                                    :loading="meetingsLoading"
                                    loading-text="Chargement..."
                                    :footer-props="$acl.check('isAuth') ? { 'items-per-page-options': [20, 30, 40, -1] } : {}"
                                    :server-items-length="total != 0 ? total : loadedMeetings.length"
                                    :hide-default-footer="!$acl.check('isAuth')"
                                >
                                    <template v-slot:item.start="{ item }">
                                        {{ $moment(item.start).format('dddd DD/MM/YYYY') }}
                                    </template>

                                    <template v-slot:item.type="{ item }">
                                        <v-btn depressed dark v-if="item.type == 'remote'" :color="isThemeAja ? '#294D5B' : ''"  class="px-11" style="border-radius: 0px; pointer-events: none;">
                                            VISIO
                                        </v-btn>
                                        <v-btn depressed :color="isThemeAja ? '#1E3945' : '#D3D3D3'" :class="isThemeAja ? 'white--text' : ''" v-if="item.type == 'onsite'" style="color: black; border-radius: 0px; pointer-events: none;">
                                            PRESENTIEL
                                        </v-btn>
                                    </template>

                                    <template v-slot:item.timeslot="{ item }">
                                        {{ $moment(item.start).format('HH[h]mm') }} - {{ $moment(item.end).format('HH[h]mm') }}
                                    </template>

                                    <template v-slot:item.places_diff="{ item }">
                                        {{ item.places - item.validated_subscribers_count }} <small>/ {{ item.places }}</small>
                                    </template>

                                    <template v-slot:item.downloads="{ item }">
                                        <template v-if="$moment().diff(item.end) < 0">
                                            <template v-if="isThePro">
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            fab
                                                            outlined
                                                            :color="isThemeAja ? '#4E983C' : 'primary'"
                                                            small
                                                            class="mx-5"
                                                            depressed
                                                            :disabled="$moment().diff(item.start, 'm') <= -2160"
                                                        >
                                                            <v-icon>get_app</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item @click="getSupport('user', item.sequence)">
                                                            <v-list-item-title>Télécharger le livret d’activités de la séance</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click="getSupport('guide', item.sequence)">
                                                            <v-list-item-title>Ouvrir le guide de la séance</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click="getSupport('pro', item.sequence)">
                                                            <v-list-item-title>Ouvrir le diapo de la séquence</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item v-if="item.type == 'remote' && item.link">
                                                            <v-list-item-title v-html="urlify(item.link)"></v-list-item-title>
                                                        </v-list-item>
                                                         <v-list-item v-if="item.type == 'onsite' && item.address">
                                                            <v-list-item-title>{{item.address}}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </template>
                                            <template v-if="$acl.check('isUserPremium')">
                                                <v-menu offset-y v-if="isValidatedSubscriber(item)">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-on="on"
                                                            outlined
                                                            :color="isThemeAja ? '#4E983C' : 'primary'"
                                                            small
                                                            class="mx-5"
                                                            depressed
                                                        >
                                                            <v-icon>get_app</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item @click="getSupport('user', item.sequence)">
                                                            <v-list-item-title>Télécharger le livret d’activités de la séance</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item v-if="item.type == 'remote' && item.link">
                                                            <v-list-item-title v-html="urlify(item.link)"></v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item v-if="item.type == 'onsite' && item.address">
                                                            <v-list-item-title>{{ item.address }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </template>
                                        </template>
                                    </template>
                                    
                                    <template v-slot:item.actions="{ item }">
                                        <!-- PRO BUTTONS -->
                                        <template v-if="isThePro">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-on="on"
                                                        fab
                                                        :color="isThemeAja ? '#4E983C' : 'primary'"
                                                        @click.stop="editMeeting(item)"
                                                        small
                                                        depressed
                                                    >
                                                        <v-icon>edit</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Modifier la séance</span>
                                            </v-tooltip>
                                        </template>
                                        <!-- subscribable only if isn't passed -->
                                        <template v-if="$moment().diff(item.end) < 0">
                                            <!-- USER BUTTONS -->
                                            <template v-if="$acl.check('isUserPremium')">
                                                <v-tooltip bottom v-if="!isValidatedSubscriber(item) && (item.places - item.validated_subscribers_count) > 0">
                                                    <template v-slot:activator="{ on }">
                                                        <ConfirmButton 
                                                            :on="on"
                                                            buttonText="Participer"
                                                            headline="Merci de confirmer votre participation"
                                                            :color="isThemeAja ? '#4E983C' : color" 
                                                            small
                                                            @confirmed="subscribeMeeting(item.id)"
                                                        />
                                                    </template>
                                                    <span>Cliquer pour participer à cette séance </span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="!isValidatedSubscriber(item) && !isWaitingSubscriber(item) && (item.places - item.validated_subscribers_count) <= 0">
                                                    <template v-slot:activator="{ on }">
                                                        <ConfirmButton 
                                                            :on="on"
                                                            buttonText="Accès liste d'attente"
                                                            headline="Vous recevrez une alerte mail lorsqu'une place se libérera sur cette séance"
                                                            color="secondary"
                                                            small
                                                            @confirmed="subscribeMeeting(item.id)"
                                                        />

                                                    </template>
                                                    <span>Cliquer pour vous abonner</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="isValidatedSubscriber(item)">
                                                    <template v-slot:activator="{ on }">
                                                        <ConfirmButton 
                                                            :on="on"
                                                            buttonText="Annuler participation"
                                                            headline="Vous êtes sur le point d'annuler votre participation à cette séance"
                                                            color="red"
                                                            small
                                                            @confirmed="unsubscribeMeeting(item.id)"
                                                            class="mx-5"
                                                        />

                                                    </template>
                                                    <span>Cliquer pour annuler votre participation</span>
                                                </v-tooltip>
                                                <v-tooltip bottom v-if="isWaitingSubscriber(item)">
                                                    <template v-slot:activator="{ on }">
                                                        <ConfirmButton 
                                                            :on="on"
                                                            buttonText="Sortir de liste d’attente"
                                                            headline="Vous êtes sur le point de supprimer votre alerte sur cette séance"
                                                            color="red"
                                                            small
                                                            @confirmed="unsubscribeMeeting(item.id)"
                                                        />

                                                    </template>
                                                    <span>Cliquer pour vous désabonner</span>
                                                </v-tooltip>
                                            </template>
                                            <!-- PUBLIC BUTTONS -->
                                            <template v-if="$acl.check('isNotAuth') || ($acl.not.check('isUserPremium') && $acl.not.check('isProPremium'))">
                                                <v-tooltip bottom v-if="!isValidatedSubscriber(item) && (item.places - item.validated_subscribers_count) > 0">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            :color="isThemeAja ? '#4E983C' : color" 
                                                            v-on="on"
                                                            @click.stop="dialogNotConnected = true"
                                                            dark
                                                            small
                                                            class="mx-1"
                                                        >
                                                            Participer
                                                        </v-btn>
                                                    </template>
                                                    <span>Cliquez pour participer à cette séance</span>
                                                </v-tooltip>
                                            </template>
                                        </template>
                                    </template>

                                    <template v-slot:expanded-item="{ headers, item }" v-if="isThePro">
                                        <td :colspan="headers.length" class="white">
                                            <v-row>
                                                <v-col>
                                                    <v-data-table 
                                                        :items="item.subscribers"
                                                        :items-per-page="-1"
                                                        item-key="id"
                                                        :headers="[
                                                            {
                                                                text: 'Nom',
                                                                align: 'left',
                                                                value: 'complete_name',
                                                            },
                                                            {
                                                                text: 'Email',
                                                                align: 'left',
                                                                value: 'email',
                                                            },
                                                            {
                                                                text: 'Téléphone',
                                                                align: 'left',
                                                                value: 'phone',
                                                            },
                                                            {
                                                                text: 'Statut',
                                                                align: 'center',
                                                                value: 'pivot.status',
                                                            },
                                                            {
                                                                text: 'Présent',
                                                                align: 'center',
                                                                value: 'pivot.attendance',
                                                                sortable: false,
                                                            },
                                                        ]"
                                                        hide-default-footer
                                                        :options="{
                                                            sortBy: ['pivot.status'],
                                                            sortDesc: [false],
                                                        }"
                                                        dense
                                                    >
                                                        <template v-slot:item.complete_name="{ item }">
                                                            <span @click="$router.push({name: 'ProUser', params: {id: item.id}})">
                                                                {{ item.complete_name }}
                                                            </span>
                                                        </template>

                                                        <template v-slot:item.pivot.status="{ item }">
                                                            <template v-if="item.pivot.status == 'validated'">
                                                                Inscrit
                                                            </template>
                                                            <template v-else>
                                                                En attente
                                                            </template>
                                                        </template>

                                                        <template v-slot:item.pivot.attendance="{ item }">
                                                            <v-edit-dialog 
                                                                :ref="'attendanceForm'+item.pivot.id" 
                                                                v-if="$moment().diff(loadedMeetings.find(m => m.id == item.pivot.meeting_id).start) >= 0"
                                                            >
                                                                <div>
                                                                    <v-icon small v-if="item.pivot.attendance">done</v-icon>
                                                                    <v-icon small v-else-if="item.pivot.attendance != null">close</v-icon>
                                                                    <span v-else>-</span>
                                                                </div>
                                                                <template v-slot:input>
                                                                    <v-radio-group 
                                                                        v-model="item.pivot.attendance" 
                                                                        row
                                                                        hide-details
                                                                        class="my-3"
                                                                        @change="saveAttendance(item.pivot)"
                                                                        :loading="attendanceLoading"
                                                                        :disabled="attendanceLoading"
                                                                    >
                                                                        <v-radio
                                                                            label="Oui"
                                                                            :value="1"
                                                                            :ripple="false"
                                                                        ></v-radio>
                                                                        <v-radio
                                                                            label="Non"
                                                                            :value="0"
                                                                            :ripple="false"
                                                                        ></v-radio>
                                                                    </v-radio-group>
                                                                </template>
                                                            </v-edit-dialog>
                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </template>

                                    <template v-slot:no-data>
                                        Aucune {{ meetingsTitle.toLowerCase() }}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-tooltip bottom v-if="isThePro">
                            <template v-slot:activator="{ on }">
                                <v-btn 
                                    v-on="on"
                                    :color="isThemeAja ? '#4E983C' : 'primary'"
                                    absolute 
                                    top 
                                    fab
                                    right 
                                    @click="editMeeting({subscribers: []})"
                                    class="mt-12"
                                    :dark="!!isThemeAja"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <span>Ajouter une séance</span>
                        </v-tooltip>
                    </v-card-text>
                </v-card>
                <v-dialog
                    v-model="dialogNotConnected"
                    width="500"
                >
                    <v-card :color="isThemeAja ? '#1E3945' : ''" :style="{'border': '1em solid white'}">
                        <v-card-text :class="{'headline py-5 px-5': true, 'white--text': $vuetify.theme.dark, 'black--text': !$vuetify.theme.dark}">
                            <template v-if="$acl.check('isNotAuth')">
                                Nous vous invitons à créer votre compte ou vous connecter si vous avez déjà un compte
                            </template>
                            <template v-else>
                                Vous avez bien créé votre compte mais vous n’êtes pas encore adhérent
                            </template>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn
                                color="secondary"
                                @click="dialogNotConnected = false"
                                class="mx-2 my-2"
                            >
                                Annuler
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="$acl.check('isNotAuth')"
                                :color="isThemeAja ? '#4E983C' : 'primary'"
                                dark
                                @click="$router.push({name: 'register'})"
                                class="mx-2 my-2"
                            >
                                Créer un compte
                            </v-btn>
                            <v-btn
                                v-if="$acl.check('isNotAuth')"
                                :color="isThemeAja ? '#4E983C' : 'primary'"
                                dark
                                @click="$router.push({name: 'login'})"
                                class="mx-2 my-2"
                            >
                                Se connecter
                            </v-btn>
                            <v-btn
                                v-else
                                :color="isThemeAja ? '#4E983C' : 'primary'"
                                dark
                                @click="$router.push({name: 'Payment', query: {subscription: 1}})"
                                class="mx-2 my-2"
                            >
                                Adhérer
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogAddMeeting" max-width="900px" persistent scrollable>
                    <v-card :color="isThemeAja ? '#1E3945' : ''">
                        <v-card-title :style="isThemeAja ? 'background-color: #4E983C!important' : ''" class="secondary white--text">
                            Ajout d'une séance
                        </v-card-title>
                        <v-card-text>
                            <MeetingForm ref="MeetingForm" v-model="editedMeeting" :workshop="value" :key="Date.now()" @savedForm="handleSavedMeetingForm" @canceledForm="handleCanceledMeetingForm"/>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>

    </div>
</template>
<script>
import JSZip from 'jszip'
import MeetingForm from '@/components/meetings/Form'
import WorkshopCard from '@/components/workshops/Card'
import { mapGetters } from 'vuex'
import '@/plugins/maps'
export default{
    components: {
        MeetingForm,
        WorkshopCard,
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        meetings: {
            type: Array,
            default: () => []
        },
        color: {
            type: String,
            default: 'primary'
        }
    },
    data(){
        return {
            meetingsHeader: [
                {
                    text: 'Date',
                    align: 'center',
                    value: 'start',
                },
                {
                    text: 'Type',
                    align: 'center',
                    value: 'type',
                    sortable: false,
                },
                {
                    text: 'Horaires',
                    align: 'center',
                    value: 'timeslot',
                    sortable: false,
                },
                {
                    text: 'Nombre de places restantes',
                    align: 'center',
                    value: 'places_diff',
                    sortable: false,
                },
                {
                    text: 'Séquence',
                    value: 'sequence', 
                    align: 'center', 
                    sortable: false
                },
                {
                    text: '',
                    value: 'downloads',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                },
            ],
            dialogAddMeeting: false,
            editedMeeting: {},
            dialogNotConnected: false,
            marker: null,
            logo_path: null,
            apiUrl: process.env.VUE_APP_API_URL,
            attendanceLoading: false,
            meetingsLoading: false,
            options: {
                page: parseInt(this.$route.query.page) || 1,
                itemsPerPage: this.$route.query.per_page ? parseInt(this.$route.query.per_page) : 20,
                sortBy: this.$route.query.sort ? [this.$route.query.sort] : ['start'],
                sortDesc: this.$route.query.direction ? [this.$route.query.direction == 'desc'] : (!this.isThePro ? [false] : [true]) ,
            },
            total: 0,
        }
    },
    computed: {
        ...mapGetters(['isThemeAja']),
        meetingsTitle() {
            return 'Séances' + (this.isThePro ? '' : ' à venir')
        },
        loadedMeetings() {
            if (this.meetingsLoading)
                return []
            return this.value.meetings.filter((m) => {
                // filter (remove passed meeting in user workshop vue)
                return ! (this.$moment().diff(m.start) > 0 && ! this.isThePro)
            })
        },
        isThePro() {
            return this.$acl.check('isProPremium') &&
                (this.value.user_id ==  this.$store.getters.getProfile.id  || 
                    (this.value.next_substitutions && this.value.next_substitutions.some(
                        obj => obj.user_id ===  this.$store.getters.getProfile.id &&
                        this.$moment(obj.start) <= this.$moment() && 
                        this.$moment(obj.end) >= this.$moment())
                    )
                );
        },
    },
    watch: {
        options: {
            handler () {
                this.fetchMeetingsSubscribers()
            },
            deep: true,
        },
    },
    mounted() {
        this.fetchMeetingsSubscribers()

        // if (this.value.type != 'remote')
        //     this.getPosition()

        if (this.value.partner && this.value.partner.logo_id)
            this.fetchPartnerLogo()

        if (this.isThePro) {
            this.meetingsHeader.push({text: 'Participants', value: 'data-table-expand', align: 'center', sortable: false})
        }
    },
    methods: {
        deleteToApi() {
            return this.$store.dispatch('workshopDeleteRequest', { id: this.value.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': 'Atelier supprimé !'});
                this.$router.push({name: 'ProWorkshops'})
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.dispatch('setErrors', err.response.data)
            });
        },
        editMeeting(meeting) {
            this.editedMeeting = Object.assign({}, meeting)
            this.editedMeeting.subscribers = this.filterSubscribers(meeting.subscribers, 'validated')
            this.editedMeeting.queued = this.filterSubscribers(meeting.subscribers, 'waiting')
            this.dialogAddMeeting = true
        },
        handleCanceledMeetingForm() {
            this.editedMeeting = {}
            this.dialogAddMeeting = false
        },
        handleSavedMeetingForm(meeting) {
            this.dialogAddMeeting = false
            let i = this.value.meetings.findIndex(m => m.id == meeting.id)
            if (i >= 0)
                Object.assign(this.value.meetings[i], meeting)
            else
                this.value.meetings.push(meeting)
        },
        subscribeMeeting(meeting_id) {
            return this.$store.dispatch('meetingSubscribeRequest', {meeting_id: meeting_id, user_id: this.$store.getters.getProfile.id}).then((response) => {
                if (response.status == 202) {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': 'Vous êtes bien inscrit sur liste d’attente de cette séance !'});
                } else {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': 'Vous êtes bien inscrit à cette séance !'});
                }

                let i = this.value.meetings.findIndex(m => m.id == meeting_id)
                if (i >= 0)
                    Object.assign(this.value.meetings[i], response.data)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.dispatch('setErrors', err.response.data)
            });
        },
        unsubscribeMeeting(meeting_id) {
            return this.$store.dispatch('meetingUnsubscribeRequest', {meeting_id: meeting_id, user_id: this.$store.getters.getProfile.id}).then((response) => {
                 if (response.status == 202) {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': 'Vous n’êtes plus sur la liste d’attente de cette séance'});
                } else {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': 'Votre annulation de participation à la séance est prise en compte !'});
                }
                let i = this.value.meetings.findIndex(m => m.id == meeting_id)
                if (i >= 0)
                    Object.assign(this.value.meetings[i], response.data)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.dispatch('setErrors', err.response.data)
            });
        },
        filterSubscribers(subscribers, status) {
            return subscribers.filter((s) => {
                return s.pivot.status == status
            })
        },
        fetchPartnerLogo() {
            let url = `${this.apiUrl}/storage/${this.value.partner.logo.path}`;
            this.logo_path = url
        },
        isValidatedSubscriber(meeting) {
            if (meeting.meeting_users)
                return meeting.meeting_users.findIndex(meeting_user => meeting_user.user_id == this.$store.getters.getProfile.id && meeting_user.status == 'validated') > -1
            return false
        },
        isWaitingSubscriber(meeting) {
            if (meeting.meeting_users)
                return meeting.meeting_users.findIndex(meeting_user => meeting_user.user_id == this.$store.getters.getProfile.id && meeting_user.status == 'waiting') > -1
            return false
        },
        saveAttendance(pivot) {
            this.attendanceLoading = true
            let queries = {
                meeting_id: pivot.meeting_id, 
                user_id: pivot.user_id,
                datas: pivot,
            }
            return this.$store.dispatch('meetingAttendanceRequest', queries).then(() => {
                this.$refs['attendanceForm'+pivot.id].cancel()
                this.attendanceLoading = false
            }).catch(() => {
                this.attendanceLoading = false
            });
        },
        urlify(text) {
            var urlRegex = /(https?:\/\/[^\s]+)/g;
            return text.replace(urlRegex, (url) =>'<a href="' + url + '" target="_blank">' + url + '</a>')
                        // .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2')
        },
        fetchMeetingsSubscribers() {
                this.meetingsLoading = true
                let search = {'workshop_id': this.value.id}
                if (! this.isThePro)
                    search.dates = [this.$moment(), this.$moment().add(999, 'years')]
                if (this.isThePro)
                    search.is_archived = false

                let queries = {
                    with: [],
                    search: JSON.stringify(search),
                    page: this.options.page,
                    sort: this.$route.query.sort ? this.$route.query.sort : 'start',
                    direction: this.options.sortDesc[0] ? 'desc' : 'asc',
                    per_page: this.options.itemsPerPage,
                }
                if(this.$store.getters.getProfile.id) { 
                    this.isThePro ? queries.with.push('subscribers') : queries.with.push('meeting_users')
                }
                this.$store.dispatch('meetingsRequest', queries)
                    .then(result => {
                        this.value.meetings = result.data
                        this.total = result.meta.total
                    })
                    .catch((err) => {
                        if (err.response.status != 403 && err.response.status != 401)
                            this.$store.dispatch('setErrors', err.response.data)
                    })
                    .finally(() => {
                        this.meetingsLoading = false
                    })
        },
        getSupport(type, sequence) {
            const queries = {
                per_page: -1,
                search: JSON.stringify({'sequence': sequence}),
                with: ['pro_attachment', 'user_attachment', 'guide_attachment'],            
            }
                return this.$store.dispatch('supportsRequest', queries).then((data) => {           
                    let results = data.data
                    if(!results[0][type+'_attachment']) {
                        return this.$store.commit('setSnack', {'type': 'error', 'msg': 'Auncun support trouvé'}); 
                    }
                    if (results.length && type == "user")
                        this.downloadAttachment(results[0][type+'_attachment'])
                    else    
                        this.previewAttachment(type+'_attachment', results[0][type+'_attachment'])
                })
                .catch((err) => {
                    if (err.response?.status != 403)
                        this.$store.dispatch('setErrors', "Une erreur est survenue lors de la récupération des supports. Veuillez réessayer plus tard.")
                });
        },
        downloadAttachment(attachment) {
            return this.$store.dispatch('attachmentDownloadRequest', { id: attachment.id}).then((data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', attachment.name);
                document.body.appendChild(link);
                link.click();
            });
        },
        async previewAttachment(type, attachment) {
            const zipData = await this.$store.dispatch('attachmentDownloadRequest', { id: attachment.id });
            // Load the zip file from a blob
            const zipRoot = await JSZip.loadAsync(zipData);
            // Get the HTML file from the zip
            const htmlFile = zipRoot.file("index.html");
            // Get the contents of the HTML file
            let htmlContent = await htmlFile.async("string");
            // Create a base URL that points to the blob object
            const baseUrl = process.env.VUE_APP_API_URL + `/api/supports/${attachment.attachable_id}/${type}/proxy/`;
            // Inject base tag in the head with baseUrl as href attribute
            var parser = new DOMParser();
            var doc = parser.parseFromString(htmlContent, 'text/html');
            var head = doc.getElementsByTagName('head')[0];
            var baseElement = document.createElement('base');
            baseElement.setAttribute('href', baseUrl)
            head.appendChild(baseElement);
            // Open the preview content in a new browser tab
            const previewContent = doc.documentElement.outerHTML;
            const previewWindow = window.open();
            previewWindow.document.write(previewContent);
            previewWindow.document.close();
        }
    }
}
</script>