<template>
    <div class="home fill-height">
        <Navigation/>
        <v-main class="fill-height" style="height: calc(100% - 120px)">
            <v-overlay absolute :value="loading" color="white" opacity="0.5">
                <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
            </v-overlay>
            <v-container fluid class="pa-0" fill-height>
                <v-row no-gutters class="fill-height">
                    <v-col>
                            <!-- :center="{lat: 44.8397584, lng: -0.5730724}"
                            :zoom="15" -->
                        <GmapMap
                            :center="{lat: 46.8202448, lng: 2.5169702}"
                            :zoom="6.4"
                            map-type-id="roadmap"
                            style="width: 100%; height: 100%"
                            ref="googleMap"
                        >
                            <gmap-info-window 
                                :options="infoOptions" 
                                :position="infoPos" 
                                :opened="showInfo" 
                                @closeclick="showInfo = false">
                            </gmap-info-window>
                            <!-- <GmapCluster :maxZoom="12"> -->
                                <GmapMarker 
                                    v-for="(marker,i) in markers"
                                    :key="i"
                                    :position="marker.pos"
                                    :clickable="true" 
                                    @click="toggleInfoWindow(marker,i)"
                                    @mouseover="toggleInfoWindow(marker,i)"
                                    :icon="marker.icon"
                                />
                            <!-- </GmapCluster> -->
                        </GmapMap>
                    </v-col>
                </v-row>
                <v-dialog v-model="showHelp" max-width="1200px">
                    <v-card color="primary">
                        <v-card-title class="justify-end">
                            <v-btn icon @click="showHelp = false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text class="text-center">
                            <div class="headline text-center font-weight-bold">
                                Ateliers VISIO accessibles quelle que soit votre localisation sur la France entière
                            </div>
                            <div class="text-center mt-3">
                                <v-btn @click="showHelp = false">Ok</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-container>
        </v-main>
    </div>
</template>
<script>
import '@/plugins/maps'
import { mapActions } from 'vuex';
export default {
    component: {
        // 
    },
    data() {
        return {
            workshops: [],
            markers: [],
            markerIcons: [
                {key: 'remote', value: "https://maps.google.com/mapfiles/ms/icons/red-dot.png"},
                {key: 'onsite', value: "https://maps.google.com/mapfiles/ms/icons/orange-dot.png"},
                {key: 'online', value: "https://maps.google.com/mapfiles/ms/icons/purple-dot.png"},
            ],
            infoPos: null,
            showInfo: false,
            clickedMarker: null,
            infoOptions: {
                content: '',
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            loading: false,
            showHelp: true,
            search: {...(this.$route.query.search ? JSON.parse(this.$route.query.search) : {type: "onsite"}), active: true},
        }
    },
    mounted() {
        this.setThemeAja(false)
        this.refreshPagination()
    },
    watch: {
    },
    methods: {
        ...mapActions(['setThemeAja']),
        toggleInfoWindow: function(marker, idx) {
            this.infoPos = marker.pos;
            this.infoOptions.content = ' \
                <div style="padding: 10px"> \
                    <h3 style="margin-bottom: 10px;color:black;">'+marker.address+'</h3><hr> \
            '
            marker.workshops.forEach((workshop) => {
                let name = workshop.name
                if (workshop.type == 'onsite')
                    name = workshop.name
                this.infoOptions.content += ' \
                    <h1 style="margin-top: 10px"> \
                        <a href="'+this.$router.resolve({ name: 'Workshop', params: {id: workshop.id} }).href+'" style="text-decoration: none"> \
                            '+name+'\
                        </a> \
                    </h1> \
                    <p style="color:black;"> \
                        <br>'
                        +'Professionnel: <strong>'+workshop.user.firstname+'</strong>'
                        +'<br>'
                        +(workshop.next_onsite_meeting_start ? 
                            'Prochaine séance présentielle: \
                            <strong>le '
                                +this.$moment(workshop.next_onsite_meeting_start).format('dddd DD MMMM YYYY') 
                                +' à ' +this.$moment(workshop.next_onsite_meeting_start).format('HH:mm')
                            +'</strong>'
                        : 'Aucune séance présentielle à venir')
                    +'</p>'
                    +'<div style="color:black; text-align:center;"> \
                        <a class="primary" \
                        href="'+this.$router.resolve({ name: 'Workshop', params: {id: workshop.id} }).href+'" \
                        style="color: white; padding: 12px 24px; border: none; border-radius: 4px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer;"> \
                            <b>M\'inscrire</b> \
                        </a> \
                    </div>'          
            })
            this.infoOptions.content += '</div>'

            if (this.clickedMarker == idx) {
              this.showInfo = !this.showInfo;
            } else {
                this.showInfo = true;
                this.clickedMarker = idx;
            }
        },
        refreshPagination () {
            const queries = {
                search: Object.keys(this.search).length > 0 ? JSON.stringify(this.search) : null,
                with: ['user', 'partner'],
            }

            this.loading = true
            this.workshops = []
            this.$store.dispatch('workshopsRequest', queries)
                .then(result => {
                    this.workshops = result.data
                    this.setMarkers(this.workshops)
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        setMarkers(workshops) {
            this.loading = true
            this.$refs.googleMap.$mapPromise.then(() => {
                workshops.forEach((workshop, k) => {
                    let address = workshop.user.street+' '+workshop.user.zip+' '+workshop.user.city
                    if (workshop.type == 'onsite' && workshop.partner)
                        address = workshop.partner.street+' '+workshop.partner.zip+' '+workshop.partner.city

                    this.getGeocode(address).then((result) => {
                        let location = result[0].geometry.location
                        this.markers.push({
                            workshops: [workshop],
                            address: address,
                            pos: {lat: location.lat(), lng: location.lng()},
                            icon: this.markerIcons.find(i => i.key == workshop.type).value ?? false
                        })
                        this.loading = false
                    })
                    .catch((status) => {
                        if (status == 'OVER_QUERY_LIMIT')
                            setTimeout(() => {this.setMarkers([workshop])}, k * 100)
                    })
                    // .finally(() => this.loading = false)
                })
            })
        },
        getGeocode(address) {
            return new Promise((resolve, reject) => {
                let geocoder = new window.google.maps.Geocoder()
                geocoder.geocode({'address': address}, function(results, status) {
                    if (status === 'OK')
                        resolve(results)
                    reject(status)
                })
            })
        }
    },
}
</script>
