var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meetings"},[_c('Navigation'),_c('v-main',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"4","color":_vm.isAja ? '#1E3945' : (_vm.$acl.check('isPro') || _vm.$acl.check('Premium') ? 'secondary': 'primary')}},[_c('v-card-text',[_c('v-row',{staticClass:"headline",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"white--text mr-5",attrs:{"x-large":""}},[_vm._v("euro")])],1),_c('v-col',{staticClass:"font-weight-bold white--text text-no-wrap"},[_vm._v("Liste de mes paiements")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.ready)?_c('v-data-table',{style:(_vm.isAja ? {backgroundColor:'#1E3945'} : {}),attrs:{"items":_vm.user.payments,"items-per-page":-1,"item-key":"id","headers":[
                            {
                                text: 'Réf',
                                align: 'left',
                                value: 'ref',
                            },
                            {
                                text: 'Date',
                                align: 'center',
                                value: 'created_at',
                            },
                            {
                                text: 'Type',
                                align: 'center',
                                value: 'type',
                            },
                            {
                                text: 'Montant',
                                align: 'center',
                                value: 'amount',
                            },
                            {
                                text: 'Statut',
                                align: 'center',
                                value: 'code',
                            },
                            {
                                text: 'Télécharger',
                                align: 'center',
                                value: 'actions',
                                sortable: false,
                            } ],"hide-default-footer":"","options":{
                            sortBy: ['created_at'],
                            sortDesc: [true],
                        },"item-class":function (item) {return {'grey--text': item.code != '00000'}}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('DD MMMM YYYY - HH[h]mm'))+" ")]}},{key:"item.amount",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAmountString(item))+" ")]}},{key:"item.code",fn:function(ref){
                        var item = ref.item;
return [(item.code == '00000')?[_vm._v("Payé")]:(item.code != '00000')?[_vm._v("Échec")]:[_vm._v("Impayé")]]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(item.donation > 0 && item.code == '00000')?_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","color":"secondary","loading":_vm.loadingDonationPrint},on:{"click":function($event){return _vm.printDonation(item)}}},[_vm._v("Reçu fiscal")]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" Aucun paiement ")]},proxy:true}],null,false,3165809490)}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }