<template>
    <div class="register" :style="isThemeAja ? { backgroundColor: '#294D5B' } : {}">
        <Navigation />
        <v-main>
            <v-container style="min-height: 100vh">
                <v-row justify="center" class="mt-5">
                    <v-col cols="10">
                        <v-card :style="isThemeAja ? { backgroundColor: '#1E3945' } : {}">
                            <CardTitle text="Créer mon compte" />
                            <v-card-text>
                                <RegisterStepper />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import RegisterStepper from '@/components/registerStepper'
import { mapGetters } from 'vuex'
export default {
    components: {
        RegisterStepper,
    },
    computed: {
        ...mapGetters(['isThemeAja']),
    },
}
</script>