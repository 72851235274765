<template>
    <div class="register d-flex flex-column fill-height">
        <Navigation/>
        <v-main class="grow">
            <v-container fluid class="fill-height align-start pb-0">
                <v-row justify="center" class="fill-height">
                    <v-col cols="12">
                        <v-card :style="isThemeAja ? { backgroundColor: '#1E3945' } : {}" v-if="showPaymentCard">
                            <CardTitle v-if="isSubscription" text="Je finalise mon adhésion"/>
                            <CardTitle v-else text="Faire un don"/>

                            <v-card-text v-if="showAdhSecretCodeForm">
                                <v-row justify="center">
                                    <v-col cols="8">
                                        <AdhCodeForm :user="$store.getters.getProfile" @submitAdhCodeForm="handleSubmitAdhCodeForm" />
                                        <v-btn :color="isThemeAja ? 'white' : 'primary'" text @click="openChangeReasonDialog()">
                                            Modifier mon orientation
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            
                            <v-card-text v-else-if="showAjaYoungForm">
                                <v-row justify="center">
                                    <v-col cols="8">
                                        <AjaYoungForm :user="$store.getters.getProfile" @submitAjaYoungForm="handleSubmitAjaYoungForm" />
                                        <v-btn class="text-right" :color="isThemeAja ? 'white' : 'primary'" text @click="openChangeReasonDialog()">
                                            Modifier mon orientation
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-text v-else-if="showAjaAssistantForm">
                              <v-row justify="center">
                                    <v-col cols="8">
                                        <AjaAssistantForm :user="$store.getters.getProfile" @submitAjaAssistantForm="handleSubmitAjaAssistantForm" />
                                        <v-btn :color="isThemeAja ? 'white' : 'primary'" text @click="openChangeReasonDialog()">
                                            Modifier mon orientation
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-text v-else-if="showQprSurvey">
                                <v-row justify="center">
                                    <v-col cols="8">
                                        <Survey v-if="showQprSurvey" v-model="survey" :from_registration="true" @saved="handleSubmitSurvey" />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                           
                            <v-card-text v-else>
                                <v-form v-on:submit.prevent="onSubmit" ref="form">
                                    <v-row v-if="isSubscription" justify="space-between" align="center" dense>
                                        <v-col cols="12">
                                            <p v-if="$acl.check('isUser')" class="mt-3 mb-0 subtitle-1">Profitez de toutes les fonctionnalités de l'outil en ligne OncoGite ! Inscription en ligne aux ateliers, suivi et rappel de vos séances,...</p>
                                            <p v-if="$acl.check('isPro')" class="mt-3 mb-0 subtitle-1">Vous pourrez ensuite créer vos atelier, les séances et gérer vos patients bénéficiares,...</p>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-switch 
                                                :disabled="isAja || isLigue || isEmployer || isRetreat || isHealthinsurance" 
                                                v-model="isSubscription"
                                                label="Adhésion"
                                                class="font-weight-bold"
                                                :color="isAja ? '#4E983C' : 'primary'"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-text-field
                                                v-if="isSubscription"
                                                v-model="subscription"
                                                label="Montant de l'adhésion"
                                                outlined
                                                disabled
                                                append-icon="euro"
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="showDiscountAlert" justify="space-between" align="center" dense>
                                        <v-col cols="12">
                                            <v-alert :color="isThemeAja ? '#4E983C' : 'primary'" text dense prominent outlined icon="thumb_up">
                                                <b>Vous bénéficiez de l'adhésion à titre gracieux.</b>
                                               
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <hr v-if="!isAjaYoung">
                                    <v-row v-if="!isAjaYoung" justify="space-between" align="center" dense>
                                        <v-col cols="12">
                                            <p class="mt-3 mb-0 subtitle-1">Notre association est reconnue d’intérêt général. Chaque don donne lieu à une réduction d’impôts. Pour les particuliers : pour 100€ versés à <oncogite/>… votre don ne vous coûte que 34€ !</p>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-switch
                                                :color="isThemeAja ? '#4E983C' : 'primary'"
                                                v-model="isDonation"
                                                label="Don"
                                                class="font-weight-bold"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-text-field
                                                :color="isThemeAja ? '#4E983C' : 'primary'"
                                                v-if="isDonation"
                                                v-model="donation"
                                                label="Montant du don"
                                                outlined
                                                append-icon="euro"
                                                hide-details
                                                type="number"
                                                min="0"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <hr>
                                    <v-row align="center">
                                        <v-col  v-if="isSubscription"  cols="12">
                                            <h3 class="mt-6 mb-2">CHARTE D’ACCÈS À LA PLATEFORME</h3>
                                            <b>Je m’engage à :</b>
                                            <v-checkbox :color="isThemeAja ? '#4E983C' : 'primary'" v-if="isSubscription" v-model="platform_accepted_terms.access" class="ml-4 mt-1">
                                                <template v-slot:label>
                                                    <div>Ne pas partager les liens d’accès à quiconque qui ne serait pas inscrit à ce parcours*</div>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox :color="isThemeAja ? '#4E983C' : 'primary'" v-if="isSubscription" v-model="platform_accepted_terms.secret" class="ml-4 mt-n4">
                                                <template v-slot:label>
                                                    <div>
                                                        À respecter le principe du secret professionnel ou secret partagé afin
                                                        qu’aucune information ou partage ultérieur sur ce qui a été abordé durant ce parcours <br/>
                                                        ne permette l’identification de la personne ou ne puisse engendrer une
                                                        quelconque malveillance*
                                                    </div>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox :color="isThemeAja ? '#4E983C' : 'primary'" v-if="isSubscription" v-model="platform_accepted_terms.discreet" class="ml-4 mt-n4">
                                                <template v-slot:label>
                                                    <div> 
                                                        À me tenir dans un lieu discret à l’abris de regards extérieurs lors ma
                                                        participation*
                                                    </div>
                                                </template>
                                            </v-checkbox>
                                            <v-checkbox :color="isThemeAja ? '#4E983C' : 'primary'" v-if="isSubscription" v-model="platform_accepted_terms.sceenshots" class="ml-4 mt-n4">
                                                <template v-slot:label>
                                                    <div style="display: flex; align-items: baseline;">
                                                        À ne procéder à aucune capture d’écran, photographie ou vidéo des participants
                                                        ou des supports (diaporama, exercices ou guide d’animation)*
                                                    </div>
                                                </template>
                                            </v-checkbox>
                                            Je comprends que cette démarche s’inscrit dans le cadre strict du respect des
                                            personnes et du matériel qui est mis à ma disposition.
                                            Le non-respect de l’intégralité de la charte donnera lieu à une interdiction d’accès à
                                            la plateforme, à ses contenus et à toute demande de remboursement.
                                        </v-col>
                                        <v-checkbox v-if="isSubscription && !$acl.check('isPro') && !isLigue &&!isAja" v-model="followed_cancerous_pathology" class="ml-4 mt-n4">
                                            <template v-slot:label>
                                                <div>Je certifie que j'ai été ou suis suivi.e pour une pathologie cancéreuse*</div>
                                            </template>
                                        </v-checkbox>
                                        <v-col class="text-right pb-5 mb-5 subtitle-1">
                                            <b>Total:</b>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-text-field
                                                class="mt-6"
                                                :value="total"
                                                :rules="[]"
                                                type="number"
                                                label="Montant total"
                                                outlined
                                                disabled
                                                append-icon="euro"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn :color="isThemeAja ? 'white' : 'primary'" text v-if="isSubscription && !$acl.check('isPro')" style="margin-top: -120px; margin-left: -10x" depressed @click="openChangeReasonDialog()">
                                                Modifier mon orientation
                                            </v-btn>
                                        </v-col>
                                        <v-col v-if="total > 0" cols="12" class="text-center">
                                            Une fois votre choix validé, vous serez redirigé vers une page de paiement sécurisée.
                                        </v-col>
                                    </v-row>
                                    <v-tooltip top :disabled="((isSubscription && (followed_cancerous_pathology && allPlatformTermsAccepted) || ($acl.check('isPro') && allPlatformTermsAccepted)))">
                                        <template v-slot:activator="{ on }">
                                            <div v-on="on">
                                                <v-btn v-on="on" type="submit" block :color="isThemeAja ? '#4E983C' : 'primary'" :loading="loading"
                                                    :disabled="(isSubscription && ((!followed_cancerous_pathology && !$acl.check('isPro')) || !allPlatformTermsAccepted))"
                                                >Valider</v-btn>
                                            </div>
                                        </template>
                                        <span>Vous devez accepter les conditions ci-dessus avant de pouvoir continuer.</span>
                                    </v-tooltip>
                                </v-form> 

                                <form ref="etransactionsForm" method="POST" :action="eTransactions.url">
                                    <input v-for="(data, i) in eTransactions.datas" :key="i"
                                        type="hidden"
                                        :name="data.key" 
                                        :value="data.value">
                                    <input type="hidden" name="PBX_HMAC" :value="eTransactions.hmac">
                                </form>
                            </v-card-text>
                        </v-card>
                        <v-dialog  v-model="showBravoCard" fullscreen hide-overlay transition="dialog-bottom-transition" persistent >
                            <v-card v-if="showBravoCard" class="fill-height">
                                <CardTitle text="Bravo"/>
                                <v-card-text class="text-center">
                                    <template v-if="$acl.check('isUser')">
                                        <v-card color="transparent" class="elevation-0" style="z-index: 1;">
                                             <v-card-text > 
                                                <div class="body-1">
                                                    <p class="mb-0">
                                                        Bienvenue dans le monde des COGITEUSES et COGITEURS…
                                                    </p>
                                                    <p class="mb-0">
                                                        Vous allez commencer votre travail de reconnexion des neurones !
                                                    </p>
                                                    <p>
                                                        <b>En tout premier lieu, nous allons faire un peu connaissance, grâce à votre PROFIL</b>
                                                    </p>
                                                    <p class="text-center">
                                                        <b>Pourquoi remplir mon PROFIL ?</b>
                                                    </p>
                                                    <p class="mb-0">
                                                        Ces informations sont <b>importantes</b> pour nous aider à <b>porter votre cause</b> auprès des politiques, des organismes d’assurance maladie, des agences régionales de soins, des mutuelles et des prévoyances. 
                                                    </p>
                                                    <p>
                                                        Grâce à cela, nous avancerons ensemble et plus loin pour permettre la reconnaissance des troubles onCOGITIFS et ainsi continuer à accompagner tous les futurs patients, comme vous aujourd’hui.
                                                    </p>
                                                </div>
                                                <v-btn color="primary" :to="{name: 'ProfileSurvey'}">Accéder à mon profil</v-btn>
                                                <div class="body-1 mt-10 text-center">
                                                    <p>
                                                        Vous ne souhaitez pas remplir votre profil.<br/>
                                                        Ce n’est pas un problème, contactez SOPHIE PRADA au 06 68 91 53 15:<br/>
                                                        Elle vous accompagnera pour choisir votre atelier et faire votre première inscription.
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                    <template v-if="$acl.check('isPro')">
                                        <v-card color="transparent" class="elevation-0" style="z-index: 1;">
                                             <v-card-text > 
                                                <div class="body-1">
                                                    <p class="mb-8">
                                                        Vous faites partie de l’équipe des Animateurs-Cogiteurs(euses).
                                                    </p>
                                                    <p class="mb-8">
                                                        Prenez le temps de trouver vos marques sur la plateforme. Notre équipe est là pour vous aider et vous accompagner.
                                                    </p>
                                                    <p class="mb-8">
                                                        N’hésitez pas à contacter Sophie par mail <a href="mailto:s.prada@oncogite.com">s.prada@oncogite.com</a> 
                                                        ou par tel <a href="tel:+33668915315">+33 6 68 91 53 15</a>
                                                    </p>
                                                    <p>
                                                        Sophie est chargée de la création de votre espace atelier. Après ce sera à vous de jouer : 
                                                    </p>
                                                    <p class="mt-n4">
                                                        - Créez et organisez les séances hebdomadaires de votre atelier 
                                                    </p>
                                                    <p class="mt-n4">
                                                        - Accédez aux supports numériques en lignes 
                                                    </p>
                                                    <p class="mt-n4 mb-12">
                                                        - Restez en contact avec les patients qui vous suivent.
                                                    </p>
                                                </div>
                                                <v-btn color="primary" :to="{name: 'ProWorkshops'}">Aller dans mon espace Pro</v-btn>
                                             </v-card-text>
                                        </v-card> 
                                    </template>
                                </v-card-text>
                                <v-footer style="z-index: 0" fixed color="transparent" class="pb-7">
                                    <v-img :src="require('@/assets/images/bandeau_bravo.png')" contain></v-img>
                                </v-footer>
                            </v-card>
                        </v-dialog>
                        <v-card v-if="showThanksCard" class="fill-height">
                            <CardTitle text="Merci !"/>
                            <v-card-text>
                                <div class="body-1 text-center">
                                    <p>Vous faites partie de nos mécènes et bienfaiteurs onCOGITEURS !</p>
                                    <p>Grâce à vous, onCOGITE va poursuivre plus loin encore ses actions,</p>
                                    <p>Plein d’autres patients pourront, comme vous, reconnecter leurs neurones et reprendre le cours de leur vie.</p>
                                    <p>Merci de votre confiance.</p>
                                </div>
                            </v-card-text>
                            <v-footer fixed color="transparent" class="pb-7">
                                <v-img :src="require('@/assets/images/bandeau_merci.png')" contain></v-img>
                            </v-footer>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-dialog persistent v-model="changeReasonDialog" max-width="80%">
                <v-card :color="isThemeAja ? '#1E3945' : ''" class="text-center pt-6">
                    <v-card-text >
                        <div>
                            <h2>Modifier le motif de ma visite</h2>
                        </div>
                        <SubscriptionReasonForm v-if="changeReasonDialog" :registration_process="registration_process" @cancelUpdateReason="changeReasonDialog = false"
                            @savedForm="updateSubscriptionReason" />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-main>
    </div>
</template>

<script>
import Survey from '@/components/surveys/Form'
import SubscriptionReasonForm from '@/components/SubscriptionReasonForm'

import AdhCodeForm from '@/components/registerSteps/AdhCodeForm'
import AjaYoungForm from '@/components/registerSteps/AjaYoungForm'
import AjaAssistantForm from '@/components/registerSteps/AjaAssistantForm'
import { mapGetters } from 'vuex'
export default {
    components: {
        Survey,
        SubscriptionReasonForm,
        AjaYoungForm,
        AjaAssistantForm,
        AdhCodeForm,
    },
    data(){
        return {
            loading: false,
            isSubscription: false,
            isDonation: false,
            showPaymentCard: true,
            showBravoCard: false,
            showThanksCard: false,
            showDiscountAlert: false,
            subscription: null,
            donation: 30,
            discounted: 0,
            eTransactions: [],
            rules: {
                positive: value => !!value || (value && value >= 1) || 'Montant minimum: 1€',
                required: value => !!value || 'Champ requis',
            },
            paymentContactMail: '',
            followed_cancerous_pathology: false,
            platform_accepted_terms: {access: false, secret: false, discreet: false, sceenshots: false },
            survey: {},
            partners: [],
            registration_process: JSON.parse(this.$store.getters.getProfile.registration_process),
            changeReasonDialog: false,
        }
    },
    computed: {
        ...mapGetters(['isThemeAja']),
        total() {
            return parseFloat((this.isSubscription ? this.subscription : 0)) + parseFloat((this.isDonation ? this.donation : 0))
        },
        allPlatformTermsAccepted() {
            const terms = this.platform_accepted_terms;
            return Object.values(terms).every(term => term === true);
        },
        isLigue() {
            return this.registration_process?.reason == "ligue"
        },
        isEmployer() {
            return this.registration_process?.reason == "employer"
        },
        isHealthinsurance() {
            return this.registration_process?.reason == "healthinsurance"
        },
        isRetreat() {
            return this.registration_process?.reason == "retreat"
        },
        isAja() {
            return this.registration_process?.reason == "aja"
        },
        isAjaYoung() {
            return this.registration_process?.reason == "aja" && this.registration_process?.aja_role == "aja_young"
        },
        isAjaAssistant() {
            return this.registration_process?.reason == "aja" && this.registration_process?.aja_role == "aja_assistant"
        },
        showQprSurvey() {
            if (!this.isSubscription || !this.$acl.check('isUser')) return false
            if (this.isAjaYoung && !this.registration_process.qprAjaYoungRegistered) return true
            if (this.isAjaAssistant && !this.registration_process.qprAjaAssistantRegistered) return true
            if (this.isLigue && !this.registration_process.qprRegistered) return true
            return false
        },
        showAdhSecretCodeForm() {
            if (!this.isSubscription || !this.$acl.check('isUser')) return false
            if (this.isLigue && !this.registration_process.validLigueCodeRegistered) return true
            if (this.isRetreat && !this.registration_process.validRetreatCodeRegistered) return true
            return false
        },
        showAjaYoungForm() {
            return this.isSubscription 
            && this.$acl.check('isUser') 
            && this.isAjaYoung 
            && !this.registration_process.ajaYoungFormRegistered
            && this.registration_process.is_invited != 1 //Is assistant invited by young, don't need to register the aja young
        },
        showAjaAssistantForm() {
            return this.isSubscription 
            && this.$acl.check('isUser') 
            && this.isAjaAssistant 
            && !this.registration_process.ajaAssistantFormRegistered 
            && this.registration_process.is_invited != 1 //Is assistant invited by young, don't need to register the aja young
        },
    },
    watch: {
        donation(val) {
            if (!val) this.donation = 0
        }
    },
    async mounted() {
        // payment response
        if (this.$route.query.code) {
            if (this.$route.query.code === '00000'){
                this.$store.dispatch('profileRequest')
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': 'Paiement effectué !'});
                        this.showPaymentCard = false
                        if (this.$route.query.subscription)
                            this.showBravoCard = true
                        else
                            this.showThanksCard = true
                    })
            } else {
                this.$store.dispatch('setErrors', {'message': 'Erreur lors du paiement'})
            }
        } else {
            // check action to do
            if (this.$route.query.subscription && !this.$store.getters.getProfile.types.includes("premium")) {
                this.isSubscription = true
                if(!this.showQprSurvey) {
                    this.showPaymentCard = true
                }
            }
            if (this.$route.query.donation || this.$acl.check('isUserPremium') || this.$acl.check('isProPremium') || ! this.isSubscription) {
                this.isDonation = true
                if (this.$route.query.donation)
                    this.donation = parseFloat(this.$route.query.donation)
            }
        }
        this.initData()
    },
    methods: {
        initData() {
            this.fetchPaymentContactMail()
            this.getPartners();
            this.checkFollowedCancerousPathology();
            this.handleSubscription();          

            if(this.showQprSurvey) {
                if(this.isAjaYoung) {
                    this.fetchSurvey('profile_aja_young')
                } else if (this.isAjaAssistant)  {
                    this.fetchSurvey('profile_aja_assistant')
                } else {
                    this.fetchSurvey('profile')
                }
            }
        },
        checkFollowedCancerousPathology() {
            if(!this.$acl.check('isPro')) {
                if(this.registration_process.reason === "ligue" || this.registration_process.reason === "aja") {
                    this.followed_cancerous_pathology = true
                } else {
                    this.followed_cancerous_pathology = false
                }
            }
        },
        handleSubscription() {
            const hasPartnerPrice = this.registration_process?.partner?.id;
            if (this.isSubscription) {
                if (hasPartnerPrice) {
                    this.fetchPartnerAmount();
                } else if (this.isAja) {
                    this.setFreeSubscription();
                } else {
                    this.fetchSubscriptionAmount();
                }
            }
        },
        formatZipCode(item) {
            if (!item || !item.zip) {
                return ''
            }
            //Spécific cases
            if (item.zip.substring(0, 2) == "97" || item.zip.substring(0, 2) == "98" ) {
                return item ? item?.zip?.slice(0, 3) : '';
            } else {
                return item ? item?.zip?.slice(0, 2) : '';
            }
        },
        async onSubmit() {
            await this.wsConnection()
            if (this.$refs.form.validate()) {
                this.loading = true
                let urlParams = []
                if (this.isSubscription)
                    urlParams.push('subscription=1')
                if (this.isDonation)
                    urlParams.push('donation='+(this.isDonation ? this.donation : 0))

                let queries = {
                    user_id: this.$store.getters.getProfile.id,
                    redirect_url: process.env.VUE_APP_URL+'/#/payment'+(urlParams.length ? '?'+urlParams.join('&') : ''),
                    subscription: this.isSubscription ? this.subscription : 0,
                    donation: this.isDonation ? this.donation : 0,
                    discounted: this.discounted,
                }
                if (this.total > 0) {
                    this.eTransactionRequest(queries)
                } else {
                    if(this.isSubscription) {
                        if (this.isAja) {
                            this.ajaAdhRequest()
                        } else {
                            this.freeAdhRequest()
                        }
                    } else {
                        this.$store.dispatch('setErrors', "Le montant de votre don doit être supérieur à 1€")
                        this.loading = false
                    }  
                }
            }
        },
        //Free adhesion is used by ligue and retreat to make user premium on platform for free
        freeAdhRequest() {
            let request = 'freeAdhesionRequest'
                let queries = {
                    user_id: this.$store.getters.getProfile.id,
                    partner_id: JSON.parse(this.$store.getters.getProfile.registration_process).partner.id,
                    adh_secret_code: JSON.parse(this.$store.getters.getProfile.registration_process).partner.adh_secret_code,
                    reason: JSON.parse(this.$store.getters.getProfile.registration_process).reason,
                    ref: 'ONCOMAN' + Date.now().toString(),
                    note:  `Adhesion gracieuse offerte par ${this.partners.find(p => p.id == this.registration_process.partner.id).name}`
                }
                this.$store.dispatch(request, queries)
                    .then(() => {
                        setTimeout(() => {
                            this.$store.commit('setSnack', {'type': 'success', 'msg': 'Adhésion enregistrée !'});
                            let userQueries = {
                                id: this.$store.getters.getProfile.id, 
                                datas: { ...this.$store.getters.getProfile, 
                                    followed_cancerous_pathology: this.followed_cancerous_pathology,
                                    platform_access_terms_accepted: this.allPlatformTermsAccepted
                                } 
                            }
                            this.$store.dispatch('userEditRequest', userQueries)
                            this.loading = false
                            this.$router.push({name: 'Home'});
                        }, 3000);
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.loading = false
                            this.$store.dispatch('setErrors', err.response.data)
                    })

        },
        //aja adhesion for user is used by aja to make user and assistant premium on platform for free
        ajaAdhRequest() {
            let request = 'ajaAdhesionRequest'
                let queries = {
                    user_id: this.$store.getters.getProfile.id,
                    adh_secret_code: JSON.parse(this.$store.getters.getProfile.registration_process).partner.adh_secret_code,
                    reason: JSON.parse(this.$store.getters.getProfile.registration_process).reason,
                    aja_role: JSON.parse(this.$store.getters.getProfile.registration_process).aja_role,
                    ref: 'ONCOMAN' + Date.now().toString(),
                    note: `Adhesion gracieuse offerte dans le cadre du projet AJA`
                }
                this.$store.dispatch(request, queries)
                    .then(() => {
                        setTimeout(() => {
                            this.$store.commit('setSnack', {'type': 'success', 'msg': 'Adhésion enregistrée !'});
                            let userQueries = {
                                id: this.$store.getters.getProfile.id, 
                                datas: { ...this.$store.getters.getProfile, 
                                    followed_cancerous_pathology: this.followed_cancerous_pathology,
                                    platform_access_terms_accepted: this.allPlatformTermsAccepted
                                } 
                            }
                            this.$store.dispatch('userEditRequest', userQueries)
                            this.loading = false
                            this.$router.push({name: 'AJAHome'});
                        }, 3000);
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.loading = false
                            this.$store.dispatch('setErrors', err.response.data)
                    })

        },
        eTransactionRequest(queries) {
            this.$store.dispatch('etransactionsCreateRequest', queries)
                    .then((eTransactions) => {
                        let userQueries = {
                            id: this.$store.getters.getProfile.id, 
                            datas: { ...this.$store.getters.getProfile, 
                                followed_cancerous_pathology: this.followed_cancerous_pathology,
                                platform_access_terms_accepted: this.allPlatformTermsAccepted
                            } 
                        }
                        this.$store.dispatch('userEditRequest', userQueries)
                        this.eTransactions = eTransactions
                        setTimeout(() => this.$refs.etransactionsForm.submit(), 3000);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.dispatch('setErrors', err.response.data)
                    })
        },
        fetchSubscriptionAmount() {
            let setting = 'subscription_amount'
            if (this.$acl.check('isPro'))
                setting = 'pro_'+setting

            this.$store.dispatch('settingRequest', {key: setting})
                .then((value) => {
                    this.subscription = parseFloat(value)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        getPartners() {
            this.$store.dispatch('partnersRequest', {})
                .then((result) => this.partners = result.data)
                .catch(() => this.$store.dispatch('setErrors', 'Error while fetching partners'))
        },
        setFreeSubscription() {
            this.subscription = 0
            this.showDiscountAlert = true
        },
        fetchPartnerAmount() {
            this.$store.dispatch('partnerRequest', {id: JSON.parse(this.$store.getters.getProfile.registration_process).partner.id})
                .then(result => {
                    this.discounted = 1
                    let partnerPrice = parseFloat(result.data.price)
                    if (partnerPrice > 0) {
                        this.subscription = partnerPrice
                    } else {
                        if (partnerPrice === 0) {
                            this.subscription = partnerPrice
                            this.showDiscountAlert = true
                        }
                    }
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        fetchPaymentContactMail() {
            this.$store.dispatch('settingRequest', {key: 'payment_contact_mail'})
                .then((value) => {
                    this.paymentContactMail = value
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        async fetchSurvey(ref) {
            this.$store.dispatch('surveyByRefRequest', { ref: ref, with: ['survey_questions'] })
                .then(result => {
                    this.survey = result.data
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        openChangeReasonDialog() {
            this.changeReasonDialog = true
        },
        updateSubscriptionReason(registrationProcess) {
            this.updateRegistrationProcess(registrationProcess)
            this.registration_process = registrationProcess
            this.changeReasonDialog = false
            this.initData()
        },
        async handleSubmitAdhCodeForm(registrationProcess) {
            if (this.registration_process.reason === "ligue") {
                await this.updateRegistrationProcess({
                    ...this.registration_process, 
                    validLigueCodeRegistered: true, 
                    partner: registrationProcess.partner 
                })
            } else {
                await this.updateRegistrationProcess({ 
                    ...this.registration_process,
                    validRetreatCodeRegistered: true,
                    partner: registrationProcess.partner
                })
            }
            this.initData()
        },
        async handleSubmitAjaYoungForm() {
            await this.updateRegistrationProcess({ ...this.registration_process, ajaYoungFormRegistered: true })
            this.initData()
        },
        async handleSubmitAjaAssistantForm() {
            await this.updateRegistrationProcess({ ...this.registration_process, ajaAssistantFormRegistered: true })
            this.initData()
        },
        async handleSubmitSurvey() {
            if (this.isLigue) {
                await this.updateRegistrationProcess({ ...this.registration_process, qprRegistered: true })
            } else if (this.isAjaAssistant) {
                await this.updateRegistrationProcess({ ...this.registration_process, qprAjaAssistantRegistered: true })
            } else if (this.isAjaYoung) {
                await this.updateRegistrationProcess({ ...this.registration_process, qprAjaYoungRegistered: true })
            }
            this.initData()
        },
        async updateRegistrationProcess(registrationProcess) {
            this.registration_process = registrationProcess
            this.$store.dispatch('userUpdateRegistrationProcessRequest', {
                id: this.$store.getters.getProfile.id,
                datas: {
                    registration_process: JSON.stringify(registrationProcess)
                }
            })
        },
        wsConnection() {
        if (this.$store.getters.getProfile.id) {
            return this.$store.dispatch('websocket/join', { uid: this.$store.getters.getProfile.id });
        } else {
            this.$store.dispatch('setErrors', "Gateway connexion error, please refresh the page and retry")
        }
},
    }
}
</script>