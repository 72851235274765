<template>
    <div class="meetings">
        <Navigation/>
        <v-main>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-card elevation="4" :color="isAja ? '#1E3945' : ($acl.check('isPro') || $acl.check('Premium') ? 'secondary': 'primary')">
                            <v-card-text>
                                <v-row no-gutters align="center" class="headline">
                                    <v-col cols="auto"><v-icon x-large class="white--text mr-5">euro</v-icon></v-col>
                                    <v-col class="font-weight-bold white--text text-no-wrap">Liste de mes paiements</v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12">
                        <v-data-table 
                            v-if="ready"
                            :style=" isAja ? {backgroundColor:'#1E3945'} : {}"
                            :items="user.payments"
                            :items-per-page="-1"
                            item-key="id"
                            :headers="[
                                {
                                    text: 'Réf',
                                    align: 'left',
                                    value: 'ref',
                                },
                                {
                                    text: 'Date',
                                    align: 'center',
                                    value: 'created_at',
                                },
                                {
                                    text: 'Type',
                                    align: 'center',
                                    value: 'type',
                                },
                                {
                                    text: 'Montant',
                                    align: 'center',
                                    value: 'amount',
                                },
                                {
                                    text: 'Statut',
                                    align: 'center',
                                    value: 'code',
                                },
                                {
                                    text: 'Télécharger',
                                    align: 'center',
                                    value: 'actions',
                                    sortable: false,
                                },
                            ]"
                            hide-default-footer
                            :options="{
                                sortBy: ['created_at'],
                                sortDesc: [true],
                            }"
                            :item-class="(item) => {return {'grey--text': item.code != '00000'}}"
                        >
                            <template v-slot:item.created_at="{ item }">
                                {{ $moment(item.created_at).format('DD MMMM YYYY - HH[h]mm') }}
                            </template>

                            <template v-slot:item.amount="{ item }">
                                {{ getAmountString(item) }}
                            </template>

                            <template v-slot:item.code="{ item }">
                                <template v-if="item.code == '00000'">Payé</template>
                                <template v-else-if="item.code != '00000'">Échec</template>
                                <template v-else>Impayé</template>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-btn
                                    v-if="item.donation > 0 && item.code == '00000'"
                                    small
                                    color="secondary"
                                    class="mx-1"
                                    @click="printDonation(item)"
                                    :loading="loadingDonationPrint"
                                >Reçu fiscal</v-btn>
                            </template>

                            <template v-slot:no-data>
                                Aucun paiement
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    data () {
        return {
            user: {},
            loadingDonationPrint: false,
            ready: false,
        }
    },
    mounted () {
        this.getUser()
    },
    computed: {
        isAja() {
            return this.$store.getters.getProfile.is_aja
        },
    },
    methods: {
        getUser() {
            this.$store.dispatch('userRequest', {id: this.$store.getters.getProfile.id, with: ['payments']})
                .then(result => {
                    this.user = result.data
                    this.ready = true
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        printDonation(payment) {
            let filename = payment.ref+'.pdf'
            this.loadingDonationPrint = true
            return this.$store.dispatch('paymentPrintDonationRequest', { id: payment.id}).then((data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                link.remove()
                this.loadingDonationPrint = false
            });
        },
        getAmountString(payment) {
            let string = ''
            if (payment.subscription > 0)
                string += (payment.subscription / 100) + ' €'
            else if (payment.discounted)
                string += '0 €'
            if (payment.donation > 0)
                string += (string.length ? ' + ' : '') + (payment.donation / 100) + ' €'
            return string
        }
    },
}
</script>
