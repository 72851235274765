<template>
    <div>
        <v-stepper class="elevation-0" v-model="e1">
            <v-stepper-header
                :style="{ 'backgroundColor': isThemeAja ? '#1E3945' : $vuetify.theme.dark ? '#1E1E1E' : 'white' }"
                color="primary">
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="e1 > 1" step="1">
                    Orientation
                </v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="e1 > 2" step="2">
                    Formulaire d'inscription
                </v-stepper-step>

                <v-divider v-if="isLigue || isRetreat"></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="e1 > 3"
                    v-if="isLigue || isRetreat" step="3">
                    Code adhérent
                </v-stepper-step>

                <v-divider v-if="isAjaYoung"></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="e1 > 3" v-if="isAjaYoung"
                    step="3">
                    Informations du parent ou aidant
                </v-stepper-step>

                <v-divider v-if="isAjaAssistant"></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" :complete="e1 > 3" v-if="isAjaAssistant"
                    step="3">
                    Informations de l'enfant
                </v-stepper-step>

                <v-divider :color="isThemeAja ? '#4E983C' : 'primary'" v-if="isLigue || isAja"></v-divider>
                <v-stepper-step :color="isThemeAja ? '#4E983C' : 'primary'" v-if="isLigue || isAja" step="4">
                    Questionnaire de profil
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items
                :style="{ 'backgroundColor': isThemeAja ? '#1E3945' : $vuetify.theme.dark ? '#1E1E1E' : 'white' }">
                <!-- STEP 1 : User registration reason -->
                <v-stepper-content step="1">
                    <OrientationForm :user="user" @updateUser="handleUserUpdate"
                        @submitOrientationForm="handleSubmitOrientationForm" />
                </v-stepper-content>

                <!-- STEP 2 : User registration form -->
                <v-stepper-content step="2">
                    <UserRegistrationForm v-if="isOrientationValid" :user="user" @updateUser="handleUserUpdate"
                        @goStepBack="handleGoStepBack" @submitUserRegistrationForm="handleSubmitUserRegistrationForm" />
                </v-stepper-content>

                <!-- STEP 3 : For Ligue or Retreat : adhCodeForm -->
                <v-stepper-content v-if="isLigue || isRetreat" step="3">
                    <AdhCodeForm v-if="isUserRegistrationValid" :user="user"
                        @submitAdhCodeForm="handleSubmitAdhCodeForm" @goStepBack="handleGoStepBack" />
                    <v-btn :color="isThemeAja ? 'white' : 'primary'" text @click="openChangeReasonDialog()">
                        Modifier mon orientation
                    </v-btn>
                </v-stepper-content>

                <!-- STEP 3 : For Aja Young -->
                <v-stepper-content v-if="isAjaYoung" step="3">
                    <AjaYoungForm :user="user" @submitAjaYoungForm="handleSubmitAjaYoungForm"
                        @goStepBack="handleGoStepBack" />
                    <v-btn :color="isThemeAja ? 'white' : 'primary'" text @click="openChangeReasonDialog()">
                        Modifier mon orientation
                    </v-btn>
                </v-stepper-content>

                <!-- STEP 3 : For Aja Assistant -->
                <v-stepper-content v-if="isAjaAssistant" step="3">
                    <AjaAssistantForm v-if="isOrientationValid" :user="user"
                        @submitAjaAssistantForm="handleSubmitAjaAssistantForm" />
                    <v-btn :color="isThemeAja ? 'white' : 'primary'" text @click="openChangeReasonDialog()">
                        Modifier mon orientation
                    </v-btn>
                </v-stepper-content>

                <!-- STEP 4 : QR Profil -->
                <v-stepper-content v-if="isLigue || isAjaAssistant || isAjaYoung" step="4">
                    <v-card flat class="mb-12">
                        <Survey v-model="survey" :from_registration="true" @saved="handleSubmitSurvey" />
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <v-dialog persistent v-model="changeReasonDialog" max-width="80%">
            <v-card :color="isThemeAja ? '#1E3945' : ''" class="text-center pt-6">
                <v-card-text>
                    <div>
                        <h2>Modifier le motif de ma visite</h2>
                    </div>
                    <SubscriptionReasonForm v-if="changeReasonDialog" @cancelUpdateReason="changeReasonDialog = false"
                        @savedForm="handleUpdateReasonForm" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import OrientationForm from '@/components/registerSteps/OrientationForm'
import UserRegistrationForm from '@/components/registerSteps/UserRegistrationForm'
import AdhCodeForm from '@/components/registerSteps/AdhCodeForm'
import AjaYoungForm from '@/components/registerSteps/AjaYoungForm'
import AjaAssistantForm from '@/components/registerSteps/AjaAssistantForm'
import Survey from '@/components/surveys/Form'
import SubscriptionReasonForm from '@/components/SubscriptionReasonForm'
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        OrientationForm,
        UserRegistrationForm,
        AdhCodeForm,
        AjaYoungForm,
        AjaAssistantForm,
        Survey,
        SubscriptionReasonForm,
    },
    data() {
        return {
            e1: 1,
            changeReasonDialog: false,
            survey: {},
            isOrientationValid: false,
            isUserRegistrationValid: false,
            user: {
                registration_process: {
                    reason: '',
                    partner: {
                        id: '',
                        adh_secret_code: '',
                    },
                },
            }
            // Test user
            // user: {
            //     lastname: 'AJA ',
            //     firstname: 'young',
            //     birthdate: '2023-01-01',
            //     street: 'dd',
            //     zip: '20200',
            //     city: 'Bastia',
            //     phone: '09 00 00 00 00',
            //     email: (Math.random() + 1).toString(36).substring(7) + '@ajatests.fr',
            //     password: '00000000',
            //     password_confirmation: '00000000',
            //     registration_process: {
            //         reason: 'ligue',
            //         partner: {
            //             id: '',
            //             adh_secret_code: '',
            //         },
            //     },
            // },
        }
    },
    watch: {
        'registrationProcess.reason'(newReason) {
            if (newReason == 'aja') {
                this.setThemeAja(true)
            } else {
                this.setThemeAja(false)
            }
        },
    },

    computed: {
        ...mapGetters(['isThemeAja']),
        registrationProcess() {
            return typeof this.user.registration_process === 'string'
                ? JSON.parse(this.user.registration_process)
                : this.user.registration_process;
        },
        isLigue() { return this.registrationProcess.reason === 'ligue' },
        isRetreat() { return this.registrationProcess.reason === 'retreat' },
        isAja() { return this.registrationProcess.reason === 'aja' },
        isAjaYoung() { return this.registrationProcess.reason === 'aja' && this.registrationProcess.aja_role === 'aja_young' },
        isAjaAssistant() { return this.registrationProcess.reason === 'aja' && this.registrationProcess.aja_role === 'aja_assistant' },

        // isThemeAja() { return this.$store.getters.getFromRoute().path.includes('/aja') || this.user?.registration_process?.reason === 'aja' },

    },
    methods: {
        ...mapActions(['setThemeAja']),
        async handleUserUpdate(updatedUser) {
            this.user = { ...this.user, ...updatedUser };
            this.$emit('updateUser', updatedUser)
        },

        async redirectUser() {
            if (this.isLigue || this.isRetreat || this.isAja) {
                this.e1 = 3
            } else {
                this.$router.push({ name: 'Welcome' })
            }
        },

        async openChangeReasonDialog() {
            this.changeReasonDialog = true
        },

        async updateRegistrationProcess(registrationProcess) {
            return new Promise((resolve, reject) => {
                this.user.registration_process = registrationProcess
                this.$store.dispatch('userUpdateRegistrationProcessRequest', {
                    id: this.user.id,
                    datas: {
                        registration_process: JSON.stringify(registrationProcess)
                    }
                })
                    .then(() => resolve())
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.dispatch('setErrors', err.response.data)
                        reject()
                    })
            })
        },

        async fetchSurvey(ref) {
            this.$store.dispatch('surveyByRefRequest', { ref: ref, with: ['survey_questions'] })
                .then(result => {
                    this.survey = result.data
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },

        async handleSubmitOrientationForm() {
            this.isOrientationValid = true
            if (!this.user.id) {
                this.e1 = 2
            } else {
                this.redirectUser()
            }
        },

        async handleSubmitUserRegistrationForm(createdUser) {
            this.user = { ...this.user, ...createdUser };
            this.isUserRegistrationValid = true
            if (!this.user.id) {
                this.e1 = 2
            } else {
                this.redirectUser()
            }
        },

        async handleSubmitAdhCodeForm(registrationProcess) {
            if (this.registrationProcess.reason === "ligue") {
                await this.updateRegistrationProcess({
                    ...this.registrationProcess,
                    validLigueCodeRegistered: true,
                    partner: registrationProcess.partner
                })
                await this.fetchSurvey("profile")
                this.e1 = 4
            } else {
                await this.updateRegistrationProcess({
                    ...this.registrationProcess,
                    validRetreatCodeRegistered: true,
                    partner: registrationProcess.partner
                })
                this.$router.push({ name: 'Payment', query: { subscription: 1 } });
            }
        },

        async handleSubmitAjaYoungForm(updatedUser) {
            this.user = { ...this.user, ...updatedUser };
            await this.updateRegistrationProcess({ ...this.registrationProcess, ajaYoungFormRegistered: true })
            await this.fetchSurvey("profile_aja_young")
            this.e1 = 4
        },

        async handleSubmitAjaAssistantForm(updatedUser) {
            this.user = { ...this.user, ...updatedUser };
            await this.updateRegistrationProcess({ ...this.registrationProcess, ajaAssistantFormRegistered: true })
            await this.fetchSurvey("profile_aja_assistant")
            this.e1 = 4
        },

        async handleSubmitSurvey() {
            if (this.isLigue) {
                await this.updateRegistrationProcess({ ...this.registrationProcess, qprRegistered: true })
            } else if (this.isAjaAssistant) {
                await this.updateRegistrationProcess({ ...this.registrationProcess, qprAjaAssistantRegistered: true })
            } else if (this.isAjaYoung) {
                await this.updateRegistrationProcess({ ...this.registrationProcess, qprAjaYoungRegistered: true })
            }

            this.$router.push({ name: 'Payment', query: { subscription: 1 } });
        },

        handleUpdateReasonForm(registrationProcess) {
            this.user.registration_process = registrationProcess
            this.changeReasonDialog = false
            this.redirectUser()
        },

        handleGoStepBack(step) {
            this.e1 = step
        }
    },
}
</script>