<template>
    <div>
        <v-form class="mt-4" ref="userRegistrationForm" v-on:submit.prevent="submitUserRegistrationForm">
            <v-text-field :color="fieldColor" v-model="localUser.lastname" label="Nom" outlined
                :rules="[rules.required]"></v-text-field>

            <v-text-field :color="fieldColor" v-model="localUser.firstname" label="Prénom" outlined
                :rules="[rules.required]"></v-text-field>

            <date-input :color="fieldColor" v-model="localUser.birthdate" label="Date de naissance"
                @save="date => setBirthDate(date)" required />

            <v-text-field :color="fieldColor" v-model="localUser.street" label="Adresse" outlined
                :rules="[rules.required]" hide-details="auto" class="mb-2"></v-text-field>

            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field :color="fieldColor" v-model="localUser.zip" label="Code postal" outlined
                        :rules="[rules.required]" @change="fetchCities" clearable></v-text-field>
                </v-col>

                <v-col cols="12" md="8">
                    <v-autocomplete :color="fieldColor" v-model="localUser.city" :items="allCities" item-text="nom"
                        item-value="nom" label="Ville" :loading="citiesLoading" :disabled="citiesLoading" outlined
                        :rules="[rules.required]" hide-details="auto" no-data-text="Aucune ville trouvée"
                        @change="fetchZip" return-object clearable></v-autocomplete>
                </v-col>
            </v-row>

            <v-text-field :color="fieldColor" v-model="localUser.phone" label="Téléphone" outlined
                :rules="[rules.required]" @input="formatPhone"></v-text-field>

            <v-text-field :color="fieldColor" v-model="localUser.email" label="E-mail" outlined
                :rules="[rules.required, rules.email]"></v-text-field>

            <v-text-field :color="fieldColor" v-model="localUser.password" label="Mot de passe" outlined
                :rules="[rules.required, rules.minlength]" :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'" @click:append="togglePasswordVisibility"></v-text-field>

            <v-text-field :color="fieldColor" v-model="localUser.password_confirmation"
                label="Confirmation du mot de passe" outlined :rules="[rules.required, rules.minlength]"
                :append-icon="showConfirm ? 'visibility' : 'visibility_off'" :type="showConfirm ? 'text' : 'password'"
                @click:append="toggleConfirmVisibility"></v-text-field>

            <v-row justify="center">
                <v-col cols="12" class="text-center">
                    <div>
                        En adhérant à nos services, vous acceptez les
                        <a href="#" :style="{ color: ajaBackground ? '#4E983C' : '' }" @click.prevent="cgDialog = true">
                            Conditions générales
                        </a>
                        de
                        <oncogite />
                    </div>
                    <div>
                        Veuillez également consulter notre notice
                        <a href="#" :style="{ color: ajaBackground ? '#4E983C' : '' }"
                            @click.prevent="confidentialityDialiog = true">
                            Protection de vos Informations Personnelles
                        </a>
                    </div>
                </v-col>

                <v-col cols="auto">
                    <vue-recaptcha class="mb-8" :sitekey="recaptchaKey" :loadRecaptchaScript="true"
                        @verify="canSubmit = true" @error="canSubmit = false"
                        @expired="canSubmit = false"></vue-recaptcha>
                </v-col>
            </v-row>

            <v-btn :color="fieldColor" type="submit" :disabled="!canSubmit || loading" :loading="loading">
                Créer mon compte
            </v-btn>
            <v-btn text @click="goStepBack">
                Retour
            </v-btn>
        </v-form>

        <!-- Alert if user is young_aja but < 18yo -->
        <v-dialog persistent v-model="minorYoungAlert" max-width="1000">
            <v-card :style="ajaBackground ? { backgroundColor: '#1E3945' } : {}" class="text-center pt-6">
                <v-card-title>
                    <h2>Bienvenu.e !</h2>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="minorYoungAlert = false"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text :color="ajaBackground ? '#4E983C' : 'primary'" class="text-left white--text">
                    Comme tu es encore mineur.e, un de tes parents ou représentant légal doit d'abord créer son compte
                    qui générera le tien automatiquement.<br />
                    La législation veut qu'ils donnent leur accord.<br /><br />
                    Reviens créer ton compte ensuite.<br />
                    Ils n’accéderont pas à ton espace personnel.<br />
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- CG DIALOG -->
        <v-dialog v-model="cgDialog" max-width="80%">
            <CGU closable @close="cgDialog = false" />
        </v-dialog>

        <!-- Confidentiality DIALOG -->
        <v-dialog v-model="confidentialityDialiog" max-width="80%">
            <Confidentiality closable @close="confidentialityDialiog = false" />
        </v-dialog>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import CGU from '@/components/CGU';
import Confidentiality from '@/components/Confidentiality';
export default {
    components: {
        VueRecaptcha,
        CGU,
        Confidentiality,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localUser: { ...this.user },
            allCities: [],
            citiesLoading: false,
            showPassword: false,
            showConfirm: false,
            canSubmit: false,
            loading: false,
            recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY,
            cgDialog: false,
            confidentialityDialiog: false,
            minorYoungAlert: false,
        };
    },
    computed: {
        fieldColor() {
            return this.ajaBackground ? '#4E983C' : 'primary';
        },
        ajaBackground() {
            const path = this.$store.getters.getFromRoute().path;
            return path.includes('/aja') || this.localUser?.registration_process?.reason === 'aja';
        },
        rules() {
            return {
                required: value => !!value || 'Champ requis',
                email: value => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value) || 'E-mail invalide',
                minlength: value => value?.length >= 8 || 'Le mot de passe doit contenir au moins 8 caractères',
            };
        },
    },
    watch: {
        localUser: {
            handler(newVal) {
                this.$emit('updateUser', newVal);
            },
            deep: true,
        },
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        toggleConfirmVisibility() {
            this.showConfirm = !this.showConfirm;
        },
        formatPhone() {
            this.localUser.phone = this.localUser.phone
                .replace(/\s/g, '')
                .match(/.{1,2}/g)
                .join(' ');
        },
        async fetchCities() {
            if (this.localUser.zip && this.localUser.zip.length === 5) {
                this.citiesLoading = true;
                const url = `https://geo.api.gouv.fr/communes?codePostal=${this.localUser.zip}`;
                try {
                    const { data } = await this.$axios.get(url);
                    this.allCities = data;
                    if (this.allCities.length === 1) {
                        this.localUser.city = this.allCities[0].nom;
                        this.localUser.zip = this.allCities[0].codesPostaux[0];
                    }
                } finally {
                    this.citiesLoading = false;
                }
            }
        },
        fetchZip() {
            if (this.localUser.city && this.localUser.city.codesPostaux) {
                this.localUser.zip = this.localUser.city.codesPostaux[0];
                this.localUser.city = this.localUser.city.nom;
            }
        },
        async submitUserRegistrationForm() {
            if (this.$refs.userRegistrationForm.validate()) {
                const createdUser = await this.createUser();
                if (createdUser) {
                    this.$emit('submitUserRegistrationForm', createdUser);
                }
            }
        },
        async setBirthDate(date) {
            this.localUser.birthdate = date;
            if (this.localUser.registration_process.reason === 'aja' && this.localUser.registration_process.aja_role === 'aja_young') {
                const age = this.$moment().diff(this.$moment(this.localUser.birthdate), 'years');
                if (age < 18) {
                    this.minorYoungAlert = true;
                    return;
                }
            }
        },
        async createUser() {
            if (this.$refs.userRegistrationForm.validate() && !this.loading) {
                if (this.localUser.registration_process.reason === 'aja' && this.localUser.registration_process.aja_role === 'aja_young') {
                    const age = this.$moment().diff(this.$moment(this.localUser.birthdate), 'years');
                    if (age < 18) {
                        this.minorYoungAlert = true;
                        return;
                    }
                }
                this.loading = true;
                try {
                    await this.$store.dispatch('registerRequest', this.localUser)
                    const { email, password } = this.localUser;
                    await this.$store.dispatch('authRequest', { email, password });
                    return this.$store.getters.getProfile
                } catch (err) {
                    if (err.response.status !== 403) {
                        this.$store.dispatch('setErrors', err.response.data);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        goStepBack() {
            this.$emit('goStepBack', 1);
        },
    },
};
</script>
