<template>
    <div class="navigation">
        <template>
            <!-- TOPBAR -->
            <v-toolbar height="120" :style="isThemeAja ? { backgroundColor: '#1E3945' } : {}">
                <v-toolbar-title class="d-flex">
                    <!-- [AJA and !Auth] Return Home classic arrow -->
                    <router-link v-if="isThemeAja && $acl.check('isNotAuth')" :to="{ name: 'Home' }" class="router-link text-no-underline" active-class="primary--text">
                        <v-btn icon class="mt-10"><v-icon large color="#4E983C">keyboard_double_arrow_left</v-icon></v-btn>
                    </router-link>

                    <!-- Home Icon -->
                    <router-link :to="isThemeAja ? { name: 'AJAHome' } : { name: 'Home' }" class="router-link text-no-underline" active-class="primary--text">
                        <v-btn icon class="mt-10"><v-icon large :color="isThemeAja ? '#4E983C' : 'primary'">home</v-icon></v-btn>
                    </router-link>

                    <!-- Oncogitiel Logo -->
                    <router-link :to="{ name: 'Oncogitiel' }" class="hidden-sm-and-down router-link text-no-underline px-4" active-class="primary--text">
                        <v-img v-if="$vuetify.theme.dark" max-width="170" class="mt-4 ml-2" :src="require('@/assets/images/logo_oncogitiel_white_new.png')"></v-img>
                        <v-img v-if="!$vuetify.theme.dark" max-width="170" class="mt-4 ml-2" :src="require('@/assets/images/logo_oncogitiel_grey.png')"></v-img>
                    </router-link>
                </v-toolbar-title>
                <v-spacer />

                <v-toolbar-items class="hidden-sm-and-down text-uppercase text-center align-center">
                    <!-- [AJA or !Auth] Home AJA  -->
                    <router-link style="margin-left: -120px;" v-if="isThemeAja || $acl.check('isNotAuth')" :to="{ name: 'AJAHome' }">
                        <v-img v-if="$vuetify.theme.dark" class=" mt-4" :src="require('@/assets/images/Logo_AJA.png')" width="200" alt="OnCOGITE" contain></v-img>
                        <v-img v-if="!$vuetify.theme.dark" class=" mt-4" :src="require('@/assets/images/Logo_AJA_white.png')" width="200" alt="OnCOGITE" contain></v-img>
                    </router-link>
                    
                    <!-- [!AJA] Workshops MAP  -->
                    <router-link v-if="!isThemeAja" :to="{ name: 'WorkshopsMap' }" class="router-link text-no-underline px-4" active-class="primary--text">
                        <v-icon color="primary" size="50px" class="mt-3 mb-2">explore</v-icon>
                        <div>Séances présentielles</div>
                    </router-link>

                    <!-- [!AJA] Workshops List  -->
                    <router-link v-if="!isThemeAja" :to="{ name: 'ListWorkshops' }"
                        class="router-link text-no-underline px-4" active-class="primary--text">
                        <v-icon color="primary" size="70px">$onsite_workshop</v-icon>
                        <v-icon color="primary" size="70px">$remote_workshop</v-icon>
                        <div>Les Ateliers</div>
                    </router-link>
                </v-toolbar-items>
                <v-spacer />

                <!-- [Auth and !AJA] Surveys Alert -->
                <QrAlert v-if="$acl.check('isAuth') && $acl.check('isUserPremium') && $acl.not.check('isProPremium') && !isThemeAja" />

                <!-- Connexion/Register/Profile -->
                <v-toolbar-items class="py-5 hidden-sm-and-down secondary--text">
                    <ProfileToolbar />
                </v-toolbar-items>

                <!-- Menu Burger -->
                <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>

                <!-- Dark/Light Theme -->
                <v-btn v-if="!isThemeAja" text icon @click.stop="changeTheme">
                    <v-icon v-if="$vuetify.theme.dark">wb_sunny</v-icon>
                    <v-icon v-else>brightness_3</v-icon>
                </v-btn>
            </v-toolbar>

            <!-- LEFTBAR -->
            <v-navigation-drawer v-model="drawer" temporary absolute id="drawer" width="315" color="secondary">
               <!-- Menu Burger -->
                <v-toolbar height="120">
                    <v-toolbar-title class="mx-auto">
                        <v-img v-if="$vuetify.theme.dark" alt="" :src="require('@/assets/images/logo_menu_blanc.png')"></v-img>
                        <v-img v-else alt="" :src="require('@/assets/images/logo_menu_noir.png')"></v-img>
                    </v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>

                <v-list dark class="py-0">
                    <v-list-item>
                        <!-- Connexion/Register/Profile -->
                        <v-list-item-title class="py-5">
                            <ProfileToolbar />
                        </v-list-item-title>
                    </v-list-item>

                    <!-- AJA Home -->
                    <v-list-item @click="$router.push({ name: 'AJAHome' })" :class="{ 'primary': $route.name == 'AJAHome' }">
                        <v-list-item-action>
                            <v-img max-width="50" :src="require('@/assets/images/Logo_AJA.png')"></v-img>
                        </v-list-item-action>
                        <v-list-item-title>
                            <div><span style="color: #4E983C">On</span>cogit<span style="color: #4E983C">AJA</span></div>
                        </v-list-item-title>
                    </v-list-item>

                    <!-- Oncogitiel -->
                    <v-list-item @click="$router.push({ name: 'Oncogitiel' })" :class="{ 'primary': $route.name == 'Oncogitiel' }">
                        <v-list-item-action>
                            <v-img v-if="$vuetify.theme.dark" max-width="50" :src="require('@/assets/images/logo_oncogitiel_white_new.png')"></v-img>
                            <v-img v-else max-width="50" :src="require('@/assets/images/logo_oncogitiel_grey.png')"></v-img>
                        </v-list-item-action>
                        <v-list-item-title>
                            <div><span style="color: orange">On</span>cogit<span style="color: orange">iel</span></div>
                        </v-list-item-title>
                    </v-list-item>

                    <!-- Workshops MAP -->
                    <v-list-item @click="$router.push({ name: 'WorkshopsMap' })"
                        :class="{ 'primary': $route.name == 'WorkshopsMap' }">
                        <v-list-item-action>
                            <v-icon color="white" size="35px" class="mr-4">explore</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Séances présentielles</v-list-item-title>
                    </v-list-item>
                    
                    <!-- Workhops -->
                    <v-list-item @click="$router.push({ name: 'ListWorkshops' })" :class="{ 'primary': $route.name == 'ListWorkshops' }">
                        <v-list-item-action>
                            <v-icon color="white" width="50px" height="50px">$remote_workshop</v-icon>
                            <v-icon color="white" width="50px" height="50px">$onsite_workshop</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Les Ateliers</v-list-item-title>
                    </v-list-item>
                    
                    <!-- [LOGGED] Meetings -->
                    <v-list-item @click="$router.push({ name: 'Meetings' })" v-if="$acl.check('isUser')" :class="{ 'primary': $route.name == 'Meetings' }">
                        <v-list-item-action>
                            <v-icon x-large color="white">alarm</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Mes séances</v-list-item-title>
                    </v-list-item>      
                </v-list>
            </v-navigation-drawer>

            <!-- UP BUTTON -->
            <v-scale-transition origin="center center">
                <v-btn fixed dark fab bottom right :color="isThemeAja ? '#1E3945' : 'primary'" v-if="scrolled" @click="$vuetify.goTo('#app')">
                    <v-icon x-large>keyboard_arrow_up</v-icon>
                </v-btn>
            </v-scale-transition>

            <!-- PREV BUTTON -->
            <v-btn v-if="$route.name != 'Home'" fixed dark fab bottom left :color="isThemeAja ? '#1E3945' : 'primary'" @click="goBack">
                <v-icon x-large>keyboard_arrow_left</v-icon>
            </v-btn>
        </template>
    </div>
</template>
<script>
import ProfileToolbar from '@/components/ProfileToolbar'
import QrAlert from '@/components/QrAlert'
import { mapGetters } from 'vuex'
export default {
    components: {
        ProfileToolbar,
        QrAlert,
    },
    data() {
        return {
            drawer: false,
            ready: false,
            scrolled: false,
            from: null,
        }
    },
    mounted() {
        this.ready = true
        window.onscroll = e => this.onScroll(e)
        if (localStorage.getItem('theme.dark') !== null)
            this.$vuetify.theme.dark = (localStorage.getItem('theme.dark') == 'true')

    },
    computed: {
        ...mapGetters(['isThemeAja']),
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.from = from
        })
    },
    methods: {
        onScroll(e) {
            if (window.pageYOffset > 70) {
                this.scrolled = true
            } else {
                this.scrolled = false
            }
        },
        goBack() {
            let from = this.$store.getters.getFromRoute()
            if (['EditWorkshop', 'CreateWorkshop'].indexOf(from.name) >= 0) {
                this.$router.go(-2)
            } else {
                this.$router.go(-1)
            }
        },
        changeTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            localStorage.setItem('theme.dark', this.$vuetify.theme.dark)
        }
    }
}
</script>
