<template>
    <div class="password-edit">
        <Navigation />
        <v-main>
            <v-container>
                <v-row justify="center" class="mt-5">
                    <v-col cols="12" :md="10">
                        <v-card flat color="#1E3945">
                            <CardTitle text="Finalisation de votre inscription à Oncogite" />
                            <v-card-text>
                                <p>
                                    Vous avez été invité à rejoindre Oncogite.<br/>
                                    Pour finaliser votre inscription, veuillez renseigner les informations ci-dessous.
                                </p>
                                <v-form class="mt-4" ref="form" @submit.prevent="onSubmit">
                                    <v-text-field v-model="user.email" label="E-mail" outlined :rules="[rules.required, rules.email]"></v-text-field>
                                    <v-text-field v-model="user.password" label="Mot de passe" outlined :rules="[rules.required, rules.minlength]" :append-icon="showPassword ? 'visibility' : 'visibility_off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"></v-text-field>
                                    <v-text-field v-model="user.password_confirmation" label="Confirmation du mot de passe" outlined :rules="[rules.required, rules.minlength]" :append-icon="showPasswordConfirm ? 'visibility' : 'visibility_off'" :type="showPasswordConfirm ? 'text' : 'password'" @click:append="showPasswordConfirm = !showPasswordConfirm"></v-text-field>
                                    <v-row justify="center">
                                        <v-col cols="12" class="text-center">
                                            <div>
                                                En adhérent à nos services, vous acceptez les
                                                <a class="primary--text" style="color: #1E3945" href="#" @click.prevent="cgDialog = true">Conditions générales</a>
                                                de
                                                <oncogite />
                                            </div>
                                            <div>
                                                Veuillez également consulter notre notice
                                                <a class="primary--text" href="#" @click.prevent="confidentialityDialiog = true">Protection de vos Informations Personnelles</a>
                                            </div>
                                        </v-col>
                                        <v-col cols="auto">
                                            <vue-recaptcha class="mb-8" :sitekey="recaptchaKey" :loadRecaptchaScript="true" @verify="canSubmit = true" @error="canSubmit = false" @expired="canSubmit = false"></vue-recaptcha>
                                        </v-col>
                                    </v-row>
                                    <v-btn depressed color="primary" type="submit" :disabled="!canSubmit || loading" :loading="loading">Créer mon compte</v-btn>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        VueRecaptcha,
    },
    data() {
        return {
            user: {},
            rules: {
                required: value => !!value || 'Champ requis',
                minlength: value => !value || (value && value.length >= 8) || '8 caractères min.',
                email: value => /.+@.+\..+/.test(value) || 'Format invalide',
            },
            loading: false,
            recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY,
            canSubmit: false,
            showPassword: false,
            showPasswordConfirm: false,
        };
    },
    mounted() {
        this.setThemeAja(true);
        // When accessing on this page, disconnect the previously connected user
        // Because it should be a different user of the one who is registering
        if (this.$store.getters.getProfile.id) {
            this.$store.dispatch('authLogout').then(() => {
                //force refresh the page to reload of navigation and profileToolbar
                this.$router.go(0)
            });
        }
    },
    methods: {
        ...mapActions(['setThemeAja']),
        onSubmit() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                const credentials = {
                    email: this.user.email,
                    token: this.$route.params.token,
                    password: this.user.password,
                    password_confirmation: this.user.password_confirmation,
                    from_complete_registration: true,
                };

                this.$store
                    .dispatch('editPassword', credentials)
                    .then(() => {
                        this.loading = false;
                        this.$router.push('/login');
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>
