<template>
    <div class="app-container">
        <Navigation />
        <v-main class="main-content">
            <v-container class="relative-container fill-height">
                <!-- Mobile homepage -->
                <v-row v-if="$vuetify.breakpoint.smAndDown">
                    <v-col cols="12" class="text-center">
                        <h1> Liste des ateliers Oncogit'AJA </h1>
                    </v-col>

                    <v-col cols="12" class="text-center">
                        <v-btn v-for="button in workshopButtons['smAndDown']" :key="button.ref" depressed
                            :color="button.color" :height="button.height" :width="button.width"
                            @click="showWorkshop(button.ref)" :loading="!getWorkshop(button.ref)"
                            class="font-weight-bold mb-6">
                            <span :style="{ color: button.textColor }">
                                <template v-if="getWorkshop(button.ref)">
                                    <span>{{ getWorkshop(button.ref).name }}</span><br />
                                    <span class="lowercase"
                                        v-if="threeNextsMeetingsStatus(getWorkshop(button.ref)) !== '(File d\'attente)'">
                                        {{ threeNextsMeetingsStatus(getWorkshop(button.ref)) }}
                                    </span>
                                    <span class="lowercase" v-if="formattedMeetingTime(button.ref)">
                                        {{ formattedMeetingTime(button.ref) }}
                                    </span>
                                </template>
                            </span>
                        </v-btn>
                        <v-menu left offset-y :close-on-content-click="false" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="surveyButtonDisabled" v-bind="attrs" v-on="on"
                                    :color="surveyButton['smAndDown'].color" :height="surveyButton['smAndDown'].height"
                                    :width="surveyButton['smAndDown'].width">
                                    <span>
                                        <b>Questionnaires</b>
                                    </span>
                                </v-btn>
                            </template>
                            <!-- Dropdown menu content -->
                            <v-list style="background-color: #1E3945;">
                                <v-list-item v-if="$acl.check('isUserPremium')" :to="{ name: 'ProfileSurvey' }">
                                    <v-list-item-title>
                                        <v-icon size="30">person</v-icon> Questionnaire de profil
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="$acl.check('isUserPremium')" :to="{ name: 'SatisfactionSurvey' }">
                                    <v-list-item-title>
                                        <v-icon size="30">sentiment_satisfied</v-icon> Questionnaire de satisfaction
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="isThemeAja && $acl.check('isUserPremium')"
                                    :to="{ name: 'CogSurvey' }">
                                    <v-list-item-title>
                                        <v-icon size="30">psychology</v-icon> Questionnaire cognitif
                                    </v-list-item-title>
                                </v-list-item>
                                <!-- Hide life quality survey for the moment -->
                                <!-- <v-list-item v-if="isThemeAja && $acl.check('isUserPremium')"
                                    :to="{ name: 'LifeQualitySurvey' }">
                                    <v-list-item-title>
                                        <v-icon size="30">help</v-icon>
                                        Questionnaire Qualité de vie
                                    </v-list-item-title>
                                </v-list-item> -->
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>

                <!-- Others screens -->
                <v-row v-else>
                    <!-- {{ $vuetify.breakpoint.name }} -->
                      
                    <v-img v-for="gif in gifs[$vuetify.breakpoint.name]" :key="gif.src" :src="gif.src"
                        :class="gif.class" :style="gifStyle(gif)" :width="gif.width" :height="gif.height"
                        :contain="gif.contain" :cover="gif.cover"></v-img>
                    <v-btn v-for="button in workshopButtons[$vuetify.breakpoint.name]" :key="button.ref"
                        :style="buttonStyle(button)" :loading="!getWorkshop(button.ref)"
                        @click="showWorkshop(button.ref)">
                        <span>
                            <template v-if="getWorkshop(button.ref)">
                                <span>{{ getWorkshop(button.ref).name }}</span><br />
                                <span v-if="threeNextsMeetingsStatus(getWorkshop(button.ref)) !== '(File d\'attente)'"
                                    class="lowercase">
                                    {{ threeNextsMeetingsStatus(getWorkshop(button.ref)) }}
                                </span>
                                <span v-if="formattedMeetingTime(button.ref)" class="lowercase">
                                    {{ formattedMeetingTime(button.ref) }}
                                </span>
                            </template>
                        </span>
                    </v-btn>
                    <v-menu left offset-y :close-on-content-click="false" transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="surveyButtonDisabled" v-bind="attrs" v-on="on"
                                :style="buttonStyle(surveyButton[$vuetify.breakpoint.name])">
                                <span>
                                    <b>Questionnaires</b>
                                </span>
                            </v-btn>
                        </template>
                        <!-- Dropdown menu content -->
                        <v-list style="min-width: 90px; background-color: #1E3945;">
                            <v-list-item v-if="$acl.check('isUserPremium')" :to="{ name: 'ProfileSurvey' }">
                                <v-list-item-title>
                                    <v-icon size="30">person</v-icon> Questionnaire de profil
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="$acl.check('isUserPremium')" :to="{ name: 'SatisfactionSurvey' }">
                                <v-list-item-title>
                                    <v-icon size="30">sentiment_satisfied</v-icon> Questionnaire de satisfaction
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="isThemeAja && $acl.check('isUserPremium')" :to="{ name: 'CogSurvey' }">
                                <v-list-item-title>
                                    <v-icon size="30">psychology</v-icon> Questionnaire cognitif
                                </v-list-item-title>
                            </v-list-item>
                            <!-- Hide life quality survey for the moment -->
                            <!-- <v-list-item v-if="isThemeAja && $acl.check('isUserPremium')"
                                :to="{ name: 'LifeQualitySurvey' }">
                                <v-list-item-title>
                                    <v-icon size="30">help</v-icon>
                                    Questionnaire Qualité de vie
                                </v-list-item-title>
                            </v-list-item> -->
                        </v-list>
                    </v-menu>
                </v-row>
                <div justify="center" align="center">
                    <v-dialog v-model="incompleteProfileDialog" max-width="80%">
                        <IncompleteProfileDialog v-if="incompleteProfileDialog"
                            :incompleteProfileMissingData="incompleteProfileMissingData" />
                    </v-dialog>
                </div>
            </v-container>
        </v-main>
    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import IncompleteProfileDialog from '@/components/IncompleteProfileDialog'

export default {
    components: {
        IncompleteProfileDialog,
    },
    data() {
        return {
            workshops: [],
            survey: {},
            incompleteProfileDialog: false,
            incompleteProfileMissingData: [],
            gifs: {
                md: [
                    { src: require('@/assets/images/aja/Stiv.gif'), width: 'auto', height: '150px', top: 'auto', bottom: '0', left: '0', right: 'auto', zIndex: '1' },
                    { src: require('@/assets/images/aja/Hannah.gif'), width: 'auto', height: '150px', top: 'auto', bottom: '0', left: '0', right: 'auto', zIndex: '1' },
                    { src: require('@/assets/images/aja/Voiture.png'), width: '120px', height: 'auto', top: '48px', bottom: 'auto', left: '-55px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Jojo.gif'), width: '770px', height: 'auto', top: '37px', bottom: 'auto', left: '170px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Sarah.gif'), width: '55px', height: 'auto', top: '72px', bottom: 'auto', left: '575px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Bou.gif'), width: '94px', height: 'auto', top: 'auto', bottom: '5px', left: '550px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Martin.png'), width: '80px', height: 'auto', top: 'auto', bottom: '3px', left: '450px', right: 'auto', zIndex: '0' },
                ],
                lg: [
                    { src: require('@/assets/images/aja/Stiv.gif'), width: 'auto', height: '210px', top: 'auto', bottom: '0', left: '0', right: 'auto', zIndex: '1' },
                    { src: require('@/assets/images/aja/Hannah.gif'), width: 'auto', height: '200px', top: 'auto', bottom: '0', left: '0', right: 'auto', zIndex: '1' },
                    { src: require('@/assets/images/aja/Voiture.png'), width: '120px', height: 'auto', top: '48px', bottom: 'auto', left: '-55px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Jojo.gif'), width: '1000px', height: 'auto', top: '40px', bottom: 'auto', left: '210px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Sarah.gif'), width: '80px', height: 'auto', top: '68px', bottom: 'auto', left: '720px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Bou.gif'), width: '120px', height: 'auto', top: 'auto', bottom: '5px', left: '550px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Martin.png'), width: '100px', height: 'auto', top: 'auto', bottom: '3px', left: '450px', right: 'auto', zIndex: '0' },
                ],
                xl: [
                    { src: require('@/assets/images/aja/Stiv.gif'), width: 'auto', height: 'auto', top: 'auto', bottom: '0', left: '0', right: 'auto', zIndex: '1' },
                    { src: require('@/assets/images/aja/Hannah.gif'), width: 'auto', height: 'auto', top: 'auto', bottom: '0', left: '0', right: 'auto', zIndex: '1' },
                    { src: require('@/assets/images/aja/Voiture.png'), width: '200px', height: 'auto', top: '48px', bottom: 'auto', left: '-55px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Jojo.gif'), width: '1300px', height: 'auto', top: '128px', bottom: 'auto', left: '180px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Sarah.gif'), width: '120px', height: 'auto', top: '125px', bottom: 'auto', left: '1262px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Bou.gif'), width: '180px', height: 'auto', top: 'auto', bottom: '5px', left: '1050px', right: 'auto', zIndex: '0' },
                    { src: require('@/assets/images/aja/Martin.png'), width: '155px', height: 'auto', top: 'auto', bottom: '3px', left: '800px', right: 'auto', zIndex: '0' },
                ],
            },

            workshopButtons: {
                smAndDown: [
                    { ref: 'cog_paid', color: '#1E3945', textColor: 'white', width: '80%', height: '50px' },
                    { ref: 'etp_cog_aja', color: '#4E983C', textColor: 'white', width: '80%', height: '50px' },
                    { ref: 'oncog_aja_1', color: '#4E983C', textColor: '#1E3945', width: '80%', height: '50px' },
                    { ref: 'oncog_aja_2', color: '#4E983C', textColor: '#1E3945', width: '80%', height: '50px' },
                    { ref: 'oncog_aja_3', color: '#4E983C', textColor: '#1E3945', width: '80%', height: '50px' },
                ],
                md: [
                    { ref: 'cog_paid', color: '#1E3945', width: '210px', textColor: 'white', top: '100px', left: '-50px' },
                    { ref: 'etp_cog_aja', color: '#4E983C', width: '210px', textColor: 'white', top: '200px', left: '0px' },
                    { ref: 'oncog_aja_1', color: '#4E983C', width: '210px', textColor: '#1E3945', top: '200px', left: '220px' },
                    { ref: 'oncog_aja_2', color: '#4E983C', width: '210px', textColor: '#1E3945', top: '200px', left: '440px' },
                    { ref: 'oncog_aja_3', color: '#4E983C', width: '210px', textColor: '#1E3945', top: '200px', left: '660px' },
                ],
                lg: [
                    { ref: 'cog_paid', color: '#1E3945', width: '250px', textColor: 'white', top: '100px', left: '-50px' },
                    { ref: 'etp_cog_aja', color: '#4E983C', width: '250px', textColor: 'white', top: '250px', left: '0px' },
                    { ref: 'oncog_aja_1', color: '#4E983C', width: '250px', textColor: '#1E3945', top: '250px', left: '280px' },
                    { ref: 'oncog_aja_2', color: '#4E983C', width: '250px', textColor: '#1E3945', top: '250px', left: '560px' },
                    { ref: 'oncog_aja_3', color: '#4E983C', width: '250px', textColor: '#1E3945', top: '250px', left: '840px' },
                ],
                xl: [
                    { ref: 'cog_paid', color: '#1E3945', width: '350px', textColor: 'white', top: '140px', left: '-50px' },
                    { ref: 'etp_cog_aja', color: '#4E983C', width: '350px', textColor: 'white', top: '400px', left: '100px' },
                    { ref: 'oncog_aja_1', color: '#4E983C', width: '350px', textColor: '#1E3945', top: '400px', left: '480px' },
                    { ref: 'oncog_aja_2', color: '#4E983C', width: '350px', textColor: '#1E3945', top: '400px', left: '860px' },
                    { ref: 'oncog_aja_3', color: '#4E983C', width: '350px', textColor: '#1E3945', top: '400px', left: '1240px' },
                ]
            },
            surveyButton: {
                smAndDown: { ref: 'qr', color: '#1E3945', width: '80%', textColor: 'white', height: '50px' },
                md: { ref: 'qr', color: '#1E3945', width: '210px', textColor: 'white', top: '100px', left: '680px' },
                lg: { ref: 'qr', color: '#1E3945', width: '250px', textColor: 'white', top: '100px', left: '900px' },
                xl: { ref: 'qr', color: '#1E3945', width: '350px', textColor: 'white', top: '140px', left: '1380px' }

            },
        };
    },
    mounted() {
        this.checkProfileComplete()
        this.fetchWorkshops();
        this.fetchSurvey();
        this.setThemeAja(true);
    },
    methods: {
        ...mapActions(['setThemeAja']),
        async fetchWorkshops() {
            this.loading = true;
            try {
                const queries = { search: JSON.stringify({ is_aja: true }) };
                const result = await this.$store.dispatch('workshopsRequest', { ...queries, with: ['user', 'partner', 'nextSubstitutions.user'] });
                this.workshops = result.data;
            } catch (err) {
                if (err.response.status !== 403) {
                    this.$store.commit('setSnack', { type: 'red', msg: err.response.data });
                }
            } finally {
                this.loading = false;
            }
        },
        showWorkshop(workshopRef) {
            const workshop = this.workshops.find(w => w.aja_ref === workshopRef);
            if (workshop) {
                this.$router.push({ name: 'AJAWorkshop', params: { id: workshop.id } });
            } else {
                this.$store.commit('setSnack', { type: 'red', msg: 'No workshop data' });
            }
        },
        threeNextsMeetingsStatus(workshop) {
            if (workshop.three_next_meetings.length === 0) return '(pas encore de séances)';
            if (!workshop.three_next_meetings.some(meeting => meeting.places > meeting.validated_subscribers_count)) return "(File d'attente)";
            return '';
        },
        formattedMeetingTime(ref) {
            const workshop = this.getWorkshop(ref);
            if (workshop && workshop.next_meeting) {
                const startDay = this.formatDate(workshop.next_meeting.start, 'dddd');
                const startTime = this.formatDate(workshop.next_meeting.start, 'HH[h]mm');
                const endTime = this.formatDate(workshop.next_meeting.end, 'HH[h]mm');
                return `${startDay} ${startTime} - ${endTime}`;
            }
            return null;
        },
        fetchSurvey() {
            this.survey = { id: 1 };
        },
        gifStyle(gif) {
            return {
                position: 'absolute',
                bottom: gif.bottom,
                top: gif.top,
                left: gif.left || 'auto',
                right: gif.right || 'auto',
                zIndex: gif.zIndex,
            };
        },
        buttonStyle(button) {
            return {
                position: 'absolute',
                top: button.top || 'auto',
                left: button.left || 'auto',
                right: button.right || 'auto',
                color: button.textColor,
                width: button.width || 'auto',
                height: '50px',
                backgroundColor: button.color,
                fontWeight: 'bold',
                zIndex: 5,
                boxShadow: 'none',
            };
        },
        formatDate(date, format) {
            return moment(date).format(format);
        },
        async checkProfileComplete() {
            if (this.$store.getters.getProfile.id) {
                await this.$store.dispatch('profileRequest')
            }
            this.incompleteProfileMissingData = []
            if (this.$store.getters.getProfile && this.$store.getters.getProfile.is_aja) {
                if (!this.$store.getters.getProfile.street || !this.$store.getters.getProfile.phone) {
                    this.incompleteProfileMissingData.push({
                        text: 'Adresse et/ou téléphone',
                        btnText: 'COMPLETER maintenant',
                        route: { name: 'ProfileEdit' },
                    });
                }
            }
            if (this.$store.getters.getProfile && this.$store.getters.getProfile.qr_info_alert) {
                this.incompleteProfileMissingData.push({
                    text: 'Questionnaire de PROFIL',
                    btnText: 'COMPLETER maintenant (5 à 10 minutes seulement !)',
                    route: { name: 'ProfileSurvey' }
                })
            }
            // Hide life_quality survey for the moment
            // if (this.$store.getters.getProfile && this.$store.getters.getProfile.life_quality_alert) {
            //     this.incompleteProfileMissingData.push({
            //         text: 'Questionnaire de QUALITE DE VIE',
            //         btnText: 'COMPLETER maintenant (5 à 10 minutes seulement !)',
            //         route: { name: 'LifeQualitySurvey' }
            //     })
            // }
            if (this.$store.getters.getProfile && this.$store.getters.getProfile.qsat_alert) {
                this.incompleteProfileMissingData.push({
                    text: 'Questionnaire de SATISFACTION',
                    btnText: 'COMPLETER maintenant (5 à 10 minutes, Go !)',
                    route: { name: 'SatisfactionSurvey' }
                })
            }
            if (this.$store.getters.getProfile && this.$store.getters.getProfile.cog_alert) {
                this.incompleteProfileMissingData.push({
                    text: 'Questionnaire COGNITIF',
                    btnText: 'COMPLETER maintenant (5 à 10 minutes pas plus !)',
                    route: { name: 'CogSurvey' }
                })
            }

            if (this.incompleteProfileMissingData.length) {
                this.incompleteProfileDialog = true
            }
        }
    },
    computed: {
        ...mapGetters(['isThemeAja']),
        getWorkshop() {
            return ref => this.workshops.find(workshop => workshop.aja_ref === ref);
        },
        surveyButtonDisabled() {
            return !this.$acl.check('isUserPremium') && !this.$store.getters.getProfile.is_aja
        }
    },
};

</script>
<style scoped>
.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.container.fill-height {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.relative-container {
    flex: 1;
    position: relative;
}

.lowercase {
    text-transform: lowercase;
}
</style>
