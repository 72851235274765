<template>
    <div class="users">
        <Navigation/>
        <v-main>
            <v-container v-if="$acl.not.check('isProPremium')">
                <v-row>
                    <v-col cols="12">
                        <NotPremiumCard/>
                    </v-col>
                </v-row>
            </v-container>

            <v-container v-else>
                <v-row>
                    <v-col cols=12>
                        <v-data-table
                            :style=" isAja ? {backgroundColor:'#1E3945'} : {}" 
                            :headers="[
                                {
                                    text: 'Créé le',
                                    align: 'center',
                                    value: 'created_at',
                                },
                                {
                                    text: 'Code',
                                    align: 'center',
                                    value: 'code',
                                    sortable: false
                                },
                                {
                                    text: 'Nom',
                                    align: 'start',
                                    value: 'lastname',
                                },
                                {
                                    text: 'Prénom',
                                    align: 'start',
                                    value: 'firstname',
                                },
                                {
                                    text: 'Email',
                                    align: 'start',
                                    value: 'email',
                                },
                                {
                                    text: 'Dernier QR Info',
                                    align: 'center',
                                    value: 'last_qpr',
                                },
                                {
                                    text: 'Dernier QSAT',
                                    align: 'center',
                                    value: 'last_qsat',
                                },
                                {
                                    text: 'Nb inscriptions',
                                    align: 'center',
                                    value: 'subscriptions_count',
                                },
                                {
                                    text: 'Nb présences',
                                    align: 'center',
                                    value: 'presences_count',
                                },
                                {
                                    text: 'Nb absences',
                                    align: 'center',
                                    value: 'absences_count',
                                },
                                {
                                    text: 'Nb désinscriptions',
                                    align: 'center',
                                    value: 'unsubscriptions_count',
                                },
                            ]"
                            :items="users"
                            :options.sync="options"
                            :server-items-length="total"
                            :loading="loading"
                            @click:row="item => $router.push({ name: 'ProUser', params: {id: item.id} })"
                            no-data-text="Aucune donnée"
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-card elevation="0" :color="isAja ? '#1E3945' : 'secondary'">
                                    <v-card-text>
                                        <v-row no-gutters align="center" class="headline mb-3">
                                            <v-col cols="auto"><v-icon x-large class="white--text mr-5">account_circle</v-icon></v-col>
                                            <v-col class="font-weight-bold white--text text-no-wrap">Mes adhérents</v-col>
                                        </v-row>

                                        <v-row dense align="center">
                                            <v-col style="min-width: 300px;">
                                                <v-text-field
                                                    v-model="search.name"
                                                    clearable
                                                    flat
                                                    solo
                                                    hide-details
                                                    prepend-inner-icon="search"
                                                    label="Rechercher"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col style="min-width: 300px;">
                                                <v-autocomplete
                                                    v-model="search.workshop_id"
                                                    :items="allWorkshops"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Atelier"
                                                    :loading="workshopsLoading"
                                                    flat
                                                    solo
                                                    clearable
                                                    hide-details
                                                    no-data-text="Aucun atelier trouvé"
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-spacer/>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </template>

                            <template v-slot:item.created_at="{ item }">
                                {{ $moment(item.created_at).format('DD/MM/YYYY') }}
                            </template>

                            <template v-slot:item.code="{ item }">
                                <span v-if="!item.member_code">{{ item.code }}</span>
                                <span v-else>{{ item.member_code }}</span>
                            </template>

                            <template v-slot:item.last_qpr="{ item }">
                                {{ item.last_qpr ? $moment(item.last_qpr.created_at).format('DD/MM/YYYY') : '' }}
                            </template>

                            <template v-slot:item.last_qsat="{ item }">
                                {{ item.last_qsat ? $moment(item.last_qsat.created_at).format('DD/MM/YYYY') : '' }}
                            </template>

                        </v-data-table>
                        <v-row v-if="!loading" class="mt-5" align="center" justify="center">
                            <v-col cols="auto">
                                <v-select 
                                    v-model="options.itemsPerPage"
                                    :items="[
                                        {value: 20, text: '20'},
                                        {value: 50, text: '50'},
                                        {value: -1, text: 'Tous'},
                                    ]"
                                    solo
                                    suffix="résultats par page"
                                    hide-details
                                    class="paginate"
                                ></v-select>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn
                                    fab
                                    :color="isAja ? '#294D5B' : 'primary'"
                                    class="mr-1"
                                    @click="prevPage"
                                    :disabled="options.page <= 1"
                                >
                                    <v-icon x-large>keyboard_arrow_left</v-icon>
                                </v-btn>
                                <span class="mx-4 grey--text">
                                    Page {{ options.page }} sur {{ lastPage }}
                                </span>
                                <v-btn
                                    fab
                                    :color="isAja ? '#294D5B' : 'primary'"
                                    class="ml-1"
                                    @click="nextPage"
                                    :disabled="options.page == lastPage"
                                >
                                    <v-icon x-large>keyboard_arrow_right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>
<script>
export default {
    components: {
        // 
    },
    data () {
        return {
            ready: false,
            users: [],
            options: {
                page: parseInt(this.$route.query.page) || 1,
                itemsPerPage: 20,
                // sortBy: this.$route.query.sortBy || [],
                // sortDesc: this.$route.query.sortDesc == 'true',
                sortBy: this.$route.query.sort ? [this.$route.query.sort] : ['created_at'],
                sortDesc: this.$route.query.direction ? [this.$route.query.direction == 'desc'] : [true],
                mustSort: false,
                multiSort: false,
            },
            sortables: [
                {value: 'lastname', text: 'Nom'},
                {value: 'last_meeting_start', text: 'Dernière séance'},
            ],
            total: 0,
            isMobile: false,
            loading: false,
            search: this.$route.query.search ? JSON.parse(this.$route.query.search) : {},
            allWorkshops: [],
            workshopsLoading: false,
        }
    },
    mounted () {
        this.refreshPagination()
        this.fetchWorkshops()
    },
    computed: {
        lastPage() {
            return Math.ceil(this.total / this.options.itemsPerPage)
        },
        // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
        computedOptions: function() {
            return Object.assign({}, this.options);
        },
        isAja() {
            return this.$store.getters.getProfile.is_aja
        },
    },
    methods: {
        prevPage() {
            if (this.options.page - 1 >= 1) this.options.page -= 1
        },
        nextPage() {
            if (this.options.page + 1 <= this.lastPage) this.options.page += 1
        },
        refreshPagination () {
            const queries = {
                page: this.options.page,
                per_page: this.options.itemsPerPage,
                sort: this.options.sortBy,
                direction: (this.options.sortDesc.length ? this.options.sortDesc[0] : false) ? 'desc' : 'asc',
                search: JSON.stringify({...this.search, pro_id: this.$store.getters.getProfile.id}),
            }

            this.loading = true
            this.users = []
            this.$store.dispatch('usersRequest', queries)
                .then(result => {
                    if (! result.data.length && this.options.page > 1)
                        this.options.page--

                    if (! result.data.length)
                        this.options.page = 0

                    this.total = result.meta.total
                    this.users = result.data
                    this.loading = false
                    this.ready = true;
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.dispatch('setErrors', err.response.data)
                })
        },
        fetchWorkshops(event) {
            this.workshopsLoading = 'secondary'
            let queries = {
                search: JSON.stringify({user_id: this.$store.getters.getProfile.id})
            }
            this.$store.dispatch('workshopsRequest', queries)
                .then((result) => {
                    this.allWorkshops = result.data
                })
                .catch((err) => {
                }).finally(() => {
                    this.workshopsLoading = false
                })
        }
    },
    watch: {
        // cannot put search in options, options watcher isn't deep enough
        search: {
            handler() {
                this.options.page = 1
                this.refreshPagination()
                this.$router.replace({
                    query: {
                        search: JSON.stringify(this.search),
                    }
                }).catch(e => console.log(e))
            },
            deep: true
        },
        // https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
        computedOptions: {
            handler (val, oldVal) {
                if (JSON.stringify(val) != JSON.stringify(oldVal)) {
                    if (val.sortDesc != oldVal.sortDesc)
                        this.options.page = 1
                    if (val.sortBy != oldVal.sortBy)
                        this.options.page = 1
                    if (! val.sortBy)
                        this.options.sortDesc = [false]

                    if (val.page == this.options.page) {
                        window.scrollTo(0, 0)
                        // window.scrollTo({ top: 0, behavior: 'smooth' })
                        // await this.$vuetify.goTo(0)

                        this.refreshPagination()

                        this.$router.replace({
                            query: {
                                page: this.options.page, 
                                search: JSON.stringify(this.search),
                                sortBy: this.options.sortBy,
                                sortDesc: this.options.sortDesc,
                            }
                        }).catch(e => console.log(e))
                    }
                }
            },
            deep: true
        },
    }
}
</script>
