<template>
    <div>
        <v-card :style="ajaBackground ? { backgroundColor: '#1E3945' } : {}" flat class="mb-12">
            <h3 class="mb-8"> Informations concernant votre parent ou aidant </h3>
            <v-form ref="ajaAssistantForm" v-on:submit.prevent="onSubmit">
                <div v-if="!noAssitant">
                    <v-text-field :color="ajaBackground ? '#4E983C' : 'primary'" v-model="assistant.lastname"
                        label="Nom de votre aidant" outlined :rules="[rules.required]"></v-text-field>
                    <v-text-field :color="ajaBackground ? '#4E983C' : 'primary'" v-model="assistant.firstname"
                        label="Prénom de votre aidant" outlined :rules="[rules.required]"></v-text-field>
                    <v-text-field :color="ajaBackground ? '#4E983C' : 'primary'" v-model="assistant.email"
                        label="E-mail de votre aidant (un email l'invitant à finaliser son inscription lui sera envoyé)"
                        outlined :rules="[rules.required, rules.email]"></v-text-field>
                </div>
                <v-switch :color="ajaBackground ? '#4E983C' : 'primary'" v-model="noAssitant"
                    label="Je préfère m'inscrire sans aidant" class="ml-4 font-weight-bold"></v-switch>
            </v-form>
        </v-card>
        <v-btn :color="ajaBackground ? '#4E983C' : 'primary'" @click="createAssistantAccount">
            Valider
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            assistant: {
                lastname: '',
                firstname: '',
                email: '',
            },
            noAssitant: false,
            rules: {
                required: value => !!value || 'Champ requis',
                email: value => {
                    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                    return pattern.test(value) || 'E-mail invalide';
                },
            },
        };
    },
    computed: {
        registrationProcess() {
            return typeof this.user.registration_process === 'string'
                ? JSON.parse(this.user.registration_process)
                : this.user.registration_process;
        },
        ajaBackground() {
            return this.registrationProcess.reason === 'aja'
        },
    },
    methods: {
        createAssistantAccount() {
            let young_id = this.$store.getters.getProfile.id
            if (this.noAssitant) {
                this.$emit('submitAjaYoungForm', { ...this.user });
                return
            }
            if (this.$refs.ajaAssistantForm.validate()) {
                this.loading = true
                this.$store.dispatch('createAssistantAccount', { ...this.assistant, 'young_id': young_id })
                    .then(() => {
                        this.$emit('submitAjaYoungForm', { ...this.user });
                    })
                    .catch((err) => {
                        this.$store.dispatch('setErrors', "Une erreur est survenue lors de la création du compte aidant");
                    })
            }
        },
    },
};
</script>