<template>
    <div class="profile">
        <Navigation />
        <v-main>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <UserDetails
                            v-if="profile"
                            v-model="profile"
                            :color="isThemeAja ? '#4E983C': 'primary'"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import UserDetails from '@/components/users/Details'
import { mapGetters } from 'vuex'
export default {
    components: {
        UserDetails,
    },
    data() {
        return {
            profile: null,
        }
    },
    mounted() {
        this.getUser()
    },
    computed: {
        ...mapGetters(['isThemeAja']),
    },
    methods: {
        getUser() {
            this.$store
                .dispatch('userRequest', { id: this.$store.getters.getProfile.id })
                .then(result => {
                    this.profile = result.data
                })
                .catch(err => {
                    if (err.response.status !== 403) {
                        this.$store.dispatch('setErrors', err.response.data)
                    }
                })
        },
    },
}
</script>
