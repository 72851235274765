<template>
    <div class="password-reset">
        <Navigation/>
        <v-main>
            <v-container>
                <v-row justify="center" class="mt-5">
                    <v-col cols="12" :md="6">
                        <v-card :color="isThemeAja ? '#2A343E' : 'primary'">
                            <CardTitle text="Réinitialisation du mot de passe"/>
                            <v-card-text>
                                <v-form
                                    ref="form"
                                    v-on:submit.prevent="send"
                                >
                                    <v-text-field
                                        v-model="email"
                                        label="E-mail"
                                        :rules="[rules.required, rules.email]"
                                        outlined
                                        :color="isThemeAja ? '#4E983C' : 'primary'"
                                    ></v-text-field>

                                    <v-btn
                                        block
                                        type="submit"
                                        :disabled="loading" 
                                        :loading="loading"
                                        :color="isThemeAja ? '#4E983C' : 'primary'"
                                    >Envoyer</v-btn>

                                </v-form>

                                <v-btn
                                    block
                                    text
                                    small
                                    color="secondary"
                                    :to="{ name: 'login' }"
                                    class="mt-4"
                                >Annuler</v-btn>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        data(){
            return {
                email: '',
                rules: {
                    required: value => !!value || 'Champ requis',
                    email: value => /.+@.+\..+/.test(value) || 'Format invalide',
                },
                loading: false,
            }
        },
        computed: {
            ...mapGetters(['isThemeAja']),
        },
        methods: {
            send: function () {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    const { email } = this;
                    this.$store.dispatch('resetPassword', { email })
                    .then(() => {
                        this.loading = false
                        this.$router.push({name: 'login'})
                    })
                    .catch((err) => {
                        this.loading = false
                    })
                }
            }
        }
    }
</script>
