import Vue from 'vue'
import Router from 'vue-router'
import store from './vuex/store'
import Login from './views/Login.vue'
import Register from './views/Register.vue'
import PasswordReset from './views/PasswordReset.vue'
import PasswordEdit from './views/PasswordEdit.vue'
import Home from './views/Home.vue'
import AJAHome from './views/AJAHome.vue'
import Payment from './views/Payment.vue'
import Payments from './views/Payments.vue'
import Unauthorized from './views/Unauthorized.vue'
import Profile from './views/Profile.vue'
import ProfileEdit from './views/ProfileEdit.vue'
import WorkshopsMap from './views/WorkshopsMap.vue'
import Workshops from './views/Workshops.vue'
import Workshop from './views/Workshop.vue'
import Meetings from './views/Meetings.vue'
import ProWorkshops from './views/Pro/Workshops.vue'
import ProWorkshop from './views/Pro/Workshop.vue'
import ProUser from './views/Pro/User.vue'
import ProUsers from './views/Pro/Users.vue'
import SatisfactionSurvey from './views/User/SatisfactionSurvey.vue'
import ProfileSurvey from './views/User/ProfileSurvey.vue'
import CogSurvey from './views/User/CogSurvey.vue'
import LifeQualitySurvey from './views/User/LifeQualitySurvey.vue'
import Welcome from './views/Welcome.vue'
import CompleteRegistration from './views/CompleteRegistration.vue'


Vue.use(Router)

const defaultHome = JSON.parse(localStorage.getItem('profile'))?.is_aja ? 'aja' : 'home'
const router = new Router({
    routes: [
        {
            path: '/unauthorized',
            name: 'Unauthorized',
            component: Unauthorized,
            meta: {
                rule: ['*']
            },
        },
        {
            path: '/',
            redirect: defaultHome,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                rule: 'isNotAuth'
            },
        },
        {
            path: '/complete-registration/:token',
            name: 'complete-registration',
            component: CompleteRegistration,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/password/reset',
            name: 'password-reset',
            component: PasswordReset,
            meta: {
                rule: 'isNotAuth'
            }
        },
        {
            path: '/password/reset/:token',
            name: 'password-edit',
            component: PasswordEdit,
            meta: {
                rule: 'isNotAuth'
            }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                rule: 'isNotAuth'
            }
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/aja',
            name: 'AJAHome',
            component: AJAHome,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/welcome',
            name: 'Welcome',
            component: Welcome,
            meta: {
                rule: 'isAuth'
            }
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            meta: {
                rule: 'isAuth'
            }
        },
        {
            path: '/profile/edit',
            name: 'ProfileEdit',
            component: ProfileEdit,
            meta: {
                rule: 'isAuth'
            }
        },
        {
            path: '/payment',
            name: 'Payment',
            component: Payment,
            meta: {
                rule: ['isAuth']
            }
        },
        {
            path: '/payments',
            name: 'Payments',
            component: Payments,
            meta: {
                rule: ['isAuth']
            }
        },
        // {
        //     path: '/meetings/:user_id?',
        //     name: 'Meetings',
        //     component: Meetings,
        //     meta: {
        //         rule: ['isAuth']
        //     }
        // },
        {
            path: '/meetings',
            name: 'Meetings',
            component: Meetings,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/meetings/:user_id',
            props: true,
            name: 'ProMeetings',
            component: Meetings,
            meta: {
                rule: ['isPro']
            }
        },
        {
            path: '/map',
            name: 'WorkshopsMap',
            component: WorkshopsMap,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/workshops',
            component: Workshops,
            name: 'Workshops',
            children: [
                {
                    path: 'list',
                    name: 'ListWorkshops',
                    meta: {
                        rule: ['*']
                    }
                },
            ],
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/workshops/:id',
            props: true,
            name: 'Workshop',
            component: Workshop,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/aja/workshops/:id',
            props: true,
            name: 'AJAWorkshop',
            component: Workshop,
            meta: {
                rule: ['*']
            }
        },
        {
            path: '/pro/workshops',
            name: 'ProWorkshops',
            component: ProWorkshops,
            meta: {
                rule: 'isPro'
            }
        },
        {
            path: '/pro/workshops/:id',
            props: true,
            name: 'ProWorkshop',
            component: ProWorkshop,
            meta: {
                rule: 'isPro'
            }
        },
        {
            path: '/pro/users',
            name: 'ProUsers',
            component: ProUsers,
            meta: {
                rule: 'isPro'
            }
        },
        {
            path: '/user/:id',
            props: true,
            name: 'ProUser',
            component: ProUser,
            meta: {
                rule: 'isPro'
            }
        },
        {
            path: '/satisfaction_survey',
            name: 'SatisfactionSurvey',
            component: SatisfactionSurvey,
            meta: {
                rule: 'isUserPremium'
            }
        },
        {
            path: '/profile_survey',
            name: 'ProfileSurvey',
            component: ProfileSurvey,
            meta: {
                rule: 'isUserPremium'
            }
        },
        {
            path: '/cog_survey',
            name: 'CogSurvey',
            component: CogSurvey,
            meta: {
                rule: 'isUserPremium'
            }
        },
        {
            path: '/life_quality_survey',
            name: 'LifeQualitySurvey',
            component: LifeQualitySurvey,
            meta: {
                rule: 'isUserPremium'
            }
        },

        {
            path: '/oncogitiel',
            name: 'Oncogitiel',
            meta: {
                rule: ['*']
            },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('access_token')) {
                    window.location.href = `${process.env.VUE_APP_ONCOGITIEL_URL}/?access_token=${localStorage.getItem('access_token')}&expires_in=${localStorage.getItem('expires_in')}`;
                } else {
                    window.location.href = `${process.env.VUE_APP_ONCOGITIEL_URL}`;
                }
            }
        },
    ]
})

router.beforeEach((to, from, next) => {
    // Check if the user is connected by looking for the profile in localStorage
    const profile = JSON.parse(localStorage.getItem('profile'));

    // AJA User don't have access to classic homePage if he is connected : redirect to AJA homepage
    if (to.name === 'Home' && profile?.is_aja) {
        return next({ name: 'AJAHome' });
    }
    // No AJA User don't have access to AJA homePage if he is connected : redirect to homepage
    if (profile?.id && (to.name === 'AJAHome' && !profile?.is_aja)) {
        return next({ name: 'Home' });
    }

    // Handle access tokens and profile requests
    if (to.query.access_token) {
        store.commit('authSuccess', { access_token: to.query.access_token });
        store.dispatch('profileRequest').then(() => {
            next();
        });
        return;
    }

    // Save the from and to routes for future navigation
    if ([from.name, to.name].indexOf('Unauthorized') < 0) {
        if (to.name == 'login') {
            store.commit('setFromRoute', to);
            store.commit('setToRoute', from);
        } else {
            store.commit('setFromRoute', from);
            store.commit('setToRoute', to);
        }
    }

    next();
});
export default router